import {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Card, Col, FloatButton, Row, Typography} from 'antd';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import styled from "styled-components";
import {getJobById} from "../../services/api";
import {GeocodedMap} from "../../components/Map";
import {IJobDetails} from "../../services/types.ts";
import {PageTitle} from "../../components/PageTitle.tsx";
import {Loader} from "../../components/Loader.tsx";
import {ContactCandidateModal} from "../candidateDetail/ContactCandidateModal.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {InfoRow} from "../messageDetail/MessageDetail.tsx";
import {MessageOutlined} from "@ant-design/icons";

const {Text} = Typography;
export const JobDetails: FC = () => {
    const [isContactModalOpen, setContactModalOpen] = useState(false);
    const {id = ""} = useParams<{ id: string }>();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {data, isLoading, error}: UseQueryResult<IJobDetails, Error> = useQuery({
        queryKey: ['jobs', id],
        queryFn: () => getJobById(id),
        enabled: !!id,
    });

    const actionButtonHandler = () => {
        setContactModalOpen(true)
    }
    const handleAfterContact = () => {
        setContactModalOpen(false)
    }

    if (id === "0") {
        return (
            <PageTitle title="Diese Stelle wurde vom Arbeitgeber deaktiviert"/>
        );
    }

    if (isLoading) {
        return <Loader/>
    }

    if (error) return <Alert message="Error" description={error.message} type="error"/>;
    if (!data) return <Alert message="No job data found" type="warning"/>;

    const address = `${data.postal_code} ${data.city} ${data.region_label} Deutschland`;
    const isPrivateUser = data._client.isPrivate;

    const mainJobItems = isPrivateUser
        ? [
            {label: 'Mindestens benötigtes Arbeitspensum', value: data.work_load_label},
            {label: 'Suchort', value: data.search_location_label}
        ]
        : [
            {label: 'Stellentyp', value: data.job_type_label},
            {label: 'Mindestens benötigtes Arbeitspensum', value: data.work_load_label},
            {label: 'Homeoffice', value: data.homeoffice_label},
            {label: 'Suchort', value: data.search_location_label}
        ];

    const employerItems = [
        {label: 'Name', value: data._client.profile_name},
        {
            label: 'Ansprechpartner',
            value: isPrivateUser ? "Privat" : `${data._client.salutation_label || "" + " " || ""} ${data._client.contact_name || ""}`
        },
        {label: 'Arbeitgebertyp', value: data._client.employer_type_label},
        {label: 'Alter', value: data._client.profile_age_label},
        {label: 'Größe', value: data._client.company_size_label},
        {label: 'Sektor', value: data._client.subsector_label},
        {label: 'Mehr Informationen', value: data._client.profile_link},
        {label: 'Beschreibung', value: data._client.profile_description},
    ];

    return (
        <div>
            <PageTitle
                title={data.title}
                onMainBtnClick={actionButtonHandler}
                description={data?._application_on_job_demand?.id ? "Arbeitgeber kontaktiert - sie werden benachrichtigt sobald er reagiert" : undefined}
                mainBtnText={!data?._application_on_job_demand?.id ? "Kontaktieren" : undefined}
            />
            <Row gutter={[16, 16]}>
                <Col span={isMobile ? 24 : 12}>
                    <Card title="Wichtigste Fakten" style={{marginBottom: '24px', backgroundColor: '#f8f9fb'}}>
                        {mainJobItems.map(item => (
                            <InfoRow key={item.label} label={item.label} value={item.value}/>
                        ))}
                    </Card>

                    <Card title="Stellenbeschreibung">
                        <Text>
                            <WhereWrapper>{data.description}</WhereWrapper>
                        </Text>
                    </Card>
                </Col>

                <Col span={isMobile ? 24 : 12}>
                    <Card title="Arbeitgeber" style={{marginBottom: '24px', backgroundColor: '#f8f9fb'}}>
                        {employerItems.filter(item => !!item.value).map(item => (
                            <InfoRow key={item.label} label={item.label} value={item.value}/>
                        ))}
                    </Card>
                    <Card title="Ort">
                        <Text>Wo?: {data.search_location_label}</Text>
                        <GeocodedMap address={address}/>
                    </Card>
                </Col>
            </Row>
            <ContactCandidateModal
                currentJob={data}
                isModalOpen={isContactModalOpen}
                handleOk={handleAfterContact}
                handleCancel={handleAfterContact}
            />

            {!data?._application_on_job_demand?.id && isMobile &&
                <FloatButton icon={<MessageOutlined/>} onClick={actionButtonHandler} type="primary"/>}
        </div>
    );
};

export const WhereWrapper = styled.pre`
    background-color: #f8f9fb !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: inherit !important;
`;

