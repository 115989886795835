import {IJobDetails, ILocation, ISearchResult} from "../services/types.ts";
export const defaultWhereOptions: ILocation[] = [
    {
        id: -1,
        created_at: Date.now(),
        label: "Alle Regionen und Postleitzahlen",
        type: "all",
        value: undefined,
        pattern: "",
        search: "",
        default: "1",
        radius: undefined,
        show: 1,
        label_candidate: "Alle Regionen und Postleitzahlen",
        show_client: 1,
        label_client: "Alle Regionen und Postleitzahlen"
    },
    {
        id: 4,
        created_at: Date.now(),
        label: "Homeoffice",
        type: "region_id",
        value: 2,
        pattern: "",
        search: "",
        default: "0",
        radius: 0,
        show: 1,
        label_candidate: "Homeoffice",
        show_client: 1,
        label_client: "Homeoffice",
    }
];

export const constructDesiredObject = (source: IJobDetails | ISearchResult) => {
    let pattern = "";
    let label = "";

    if (source.regions_id !== 0) {
        pattern = source.region_label;
        label = source.search_location_label;
    } else if (source.coordinates) {
        pattern = `${source.postal_code} ${source.city}`;
        label = source.search_location_label;
    }

    return {
        created_at: source.created_at,
        id: source.regions_id !== 0 ? source.regions_id : source.search_location_id,
        label: label,
        label_candidate: label,
        label_client: label,
        pattern: pattern,
        radius: source.radius,
        search: label,
        type: source.regions_id !== 0 ? "region_id" : "location_id",
        value: (source.regions_id !== 0 ? source.regions_id : source.search_location_id).toString()
    };
}

export type SelectOptions = { id: number, value: string, label: string }[];
export const alertOptions: SelectOptions = [
    {id: 1, value: "1", label: 'In Echtzeit (aktuelle Einstellung)'},
    {id: 2, value: "2", label: '1x Täglich'},
    {id: 3, value: "3", label: 'Keine Email senden'},
]

export const createDescriptionItems = (items: Record<string, string | undefined>) =>
    Object.entries(items)
        .filter(([, value]) => value)
        .map(([key, value], index) => ({key: index.toString(), label: key, children: value}));

