import {Modal, Radio, Space} from 'antd';
import {FC} from "react";

interface SurveyModalProps {
    visible: boolean;
    onOk: () => void;
    onCancel: () => void;
    surveyResponse: number | null;
    setSurveyResponse: (value: number) => void;
}


export const SurveyModal: FC<SurveyModalProps> = ({
                                                      visible,
                                                      onOk,
                                                      onCancel,
                                                      surveyResponse,
                                                      setSurveyResponse
                                                  }) => {
    const options = [
        'Keine Angabe',
        'Ich wollte nur einmal schauen',
        'Ich habe über Silvertalent einen Job gefunden',
        'Ich habe anders einen Job gefunden'
    ];

    return (
        <Modal
            title="Ihre Jobsuche wurde deaktiviert"
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
            okText="Speichern"
            cancelText="Abbrechen"
        >
            <h3>Warum haben Sie Ihre Jobsuche beendet?</h3>
            <Radio.Group onChange={(e) => setSurveyResponse(e.target.value)} value={surveyResponse}>
                <Space direction="vertical">
                    {options.map((option, index) => (
                        <Radio key={index} value={index + 1}>
                            {option}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Modal>
    );
};

