import {FC, useEffect} from 'react';
import {Alert, Button, Form, Input, Modal, Select, Space} from "antd";
import {Link} from "react-router-dom";
import {AlertWrapper} from "../../../deleteProfile/DeleteProfile.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

export type initialValuesType = {
    first_name: string
    last_name: string
    phone: string
    email: string
    code: string
    salutation_id?: number
    complete: boolean
    complete_name: boolean
    _salutation: {
        id: number
        created_at: number
        label: string
    }
}

export type formValuesType = {
    values: {
        sector_id: number
        subsector_id: number
        job_position: string
        experience_years_id: number
    }
}

interface ContactDetailsModalProps {
    visible: boolean;
    onCancel: () => void;
    onFinish: (values: formValuesType) => void;
    initialValues: initialValuesType;
    isLoading: boolean;
}

const salutationOptions = [
    {value: 1, label: "Herr"},
    {value: 2, label: "Frau"}
];

export const ContactDetailsModal: FC<ContactDetailsModalProps> = ({
                                                                      visible,
                                                                      onCancel,
                                                                      onFinish,
                                                                      initialValues,
                                                                      isLoading
                                                                  }) => {
    const [form] = Form.useForm();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    useEffect(() => {
        const formValues = {...initialValues};
        if (formValues._salutation.label === "Herr") {
            formValues.salutation_id = 1;
        }else if (formValues._salutation.label === "Frau") {
            formValues.salutation_id = 2;
        }else {
            formValues.salutation_id = undefined;
        }
        form.setFieldsValue(formValues);
    }, [form, initialValues]);

    const handleOk = () => {
        form.submit();
    };

    return (
        <Modal
            width={600}
            open={visible}
            title="Ihre Kontaktdaten"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Speichern
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item name="salutation_id" label="Anrede">
                    <Select
                        placeholder="Wählen Sie eine Anrede"
                        options={salutationOptions}
                        allowClear
                    />
                </Form.Item>
                <Form.Item name="first_name" label="Vorname">
                    <Input size={isMobile? "large" : "middle"}/>
                </Form.Item>
                <Form.Item name="last_name" label="Nachname">
                    <Input size={isMobile? "large" : "middle"}/>
                </Form.Item>
                <Form.Item name="phone" label="Telefonnummer:">
                    <Input size={isMobile? "large" : "middle"}/>
                </Form.Item>
            </Form>
            <AlertWrapper>
                <Alert
                    message={`Ihre Emailadresse ist Teil Ihrer Zugangsdaten. Sie können Sie nur über "Mein Konto > Zugangsdaten" ändern.`}
                    type="info"
                    showIcon
                    style={{marginTop: 8, marginBottom: 8}}
                    action={
                        <Space direction="vertical">
                            <Link to="/privacy">
                                <Button type="default">
                                    Zu den Zugangsdaten
                                </Button>
                            </Link>
                        </Space>
                    }
                />
            </AlertWrapper>
        </Modal>
    );
};
