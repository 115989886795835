import {useContext, useState} from 'react';
import {PageTitle} from "../../components/PageTitle.tsx";
import {Button, Form, Input, List, message, Modal, Popconfirm} from 'antd';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
    addOutreachTemplate,
    deleteOutreachTemplate,
    getAllOutreachTemplates,
    updateOutreachTemplate
} from "../../services/api.ts";
import {OutreachTemplate, OutreachTemplateRequest} from "../../services/types.ts";
import {AuthContext} from "../../context/AuthContext.tsx";
import {Loader} from "../../components/Loader.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

export const Templates = () => {
    const {user} = useContext(AuthContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState<OutreachTemplate | null>(null);
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const {data: templates = [], isLoading, isError} = useQuery({
        queryKey: ['templates'],
        queryFn: getAllOutreachTemplates,
    });

    const addTemplateMutation = useMutation({
        mutationFn: addOutreachTemplate,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['templates']});
            message.success('Vorlage erfolgreich hinzugefügt');
            handleModalClose();
        },
        onError: () => {
            message.error('Vorlage konnte nicht hinzugefügt werden');
        },
    });

    const updateTemplateMutation = useMutation({
        mutationFn: updateOutreachTemplate,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['templates']});
            message.success('Vorlage erfolgreich aktualisiert');
            handleModalClose();
        },
        onError: () => {
            message.error('Die Vorlage konnte nicht aktualisiert werden.');
        },
    });

    const deleteTemplateMutation = useMutation({
        mutationFn: deleteOutreachTemplate,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['templates']});
            message.success('Vorlage erfolgreich gelöscht');
        },
        onError: () => {
            message.error('Vorlage konnte nicht gelöscht werden');
        },
    });

    const handleAddTemplate = () => {
        setEditingTemplate(null);
        setIsModalVisible(true);
    };

    const handleEditTemplate = (template: OutreachTemplate) => {
        setEditingTemplate(template);
        form.setFieldsValue(template);
        setIsModalVisible(true);
    };

    const handleDeleteTemplate = (id: number) => {
        deleteTemplateMutation.mutate(id);
    };

    const handleModalOk = () => {
        form.validateFields().then((values) => {
            if (editingTemplate) {
                updateTemplateMutation.mutate({id: editingTemplate.id, ...values, users_id: user!.id});
            } else {
                addTemplateMutation.mutate(values as OutreachTemplateRequest);
            }
        });
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setEditingTemplate(null);
        form.resetFields();
    };

    if (isLoading) {
        return <Loader/>;
    }

    if (isError) {
        return <div>Fehler beim Laden der Vorlagen. Bitte versuchen Sie es später erneut.</div>;
    }

    return (
        <div>
            <PageTitle title="Meine Vorlagen" onMainBtnClick={handleAddTemplate} mainBtnText="Vorlagen anlegen"/>

            <List
                dataSource={templates}
                renderItem={(item: OutreachTemplate) => (
                    <List.Item
                        style={{
                            backgroundColor: "white",
                            marginBottom: 16,
                            padding: 16,
                            borderRadius: 4,
                            boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                            flexDirection: isMobile ? "column" : "row",
                            alignItems: isMobile ? "flex-start" : "center"
                        }}
                        actions={[
                            <Button key="edit" onClick={() => handleEditTemplate(item)}>Bearbeiten</Button>,
                            ...[item.users_id !== 5 && <Popconfirm
                                    title="Sind Sie sicher, dass Sie diese Vorlage löschen möchten?"
                                    onConfirm={() => handleDeleteTemplate(item.id)}
                                    okText="Ja"
                                    cancelText="Nein"
                                >
                                    <Button key="delete" type="text" danger>Löschen</Button>
                                </Popconfirm>]

                        ]}
                    >
                        <List.Item.Meta
                            title={item.name}
                            description={item.message}
                            style={{width: "100%"}}
                        />
                    </List.Item>
                )}
            />

            <Modal
                title={editingTemplate ? "Vorlage bearbeiten" : "Neue Vorlage erstellen"}
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalClose}
                cancelText={"Abbrechen"}
                okText={editingTemplate ? "Speichern" : "Erstellen"}
                confirmLoading={addTemplateMutation.isPending || updateTemplateMutation.isPending}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{required: true, message: 'Ihr Titel'}]}
                    >
                        <Input size={isMobile? "large" : "middle"}/>
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label="Nachricht an Arbeitgeber"
                        rules={[{required: true, message: 'Kandidatenansprache'}]}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
