import { FC, useEffect } from 'react';
import { Button, Checkbox, Form, Modal, Radio, Select } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getWorkLoadOptions } from "../../../../services/api";

export type initialValuesType = {
    languages: string
    age: string
    it: string
    lastemployer_sector: string
    self_description: string
    languages_id: Array<number>
    language_german: string
    language_english: string
    language_other: string
    potential_job_1: string
    potential_job_2: string
    potential_job_3: string
    potential_job_4: string
    potential_job_5: string
    potential_job_6: string
    potential_job_7: string
    potential_job_8: string
    potential_job_9: string
    potential_job_10: string
    potential_job_11: string
    potential_job_12: string
    potential_job_13: string
    potential_job_14: string
    potential_job_15: string
    potential_job_17: string
    potential_job_16: string
    potential_job_18: string
    potential_job_19: string
    potential_job_20: string
    potential_job_21: string
    potential_job_22: string
    potential_job_23: string
    potential_job_24: string
    work_load: string
    flexibility: string
    work_where: string
    motivation: string
    salary: string
    tempvsperm: string
    work_lastemployer: string
    work_honorary: string
    work_type: string
    work_load_candidate_id: number
    work_where_candidate_id: number
    work_honorary_id: number
    work_fully_remote_id: number
}

export type formValuesType = {
    work_load_candidate_id: number;
    work_honorary_id: number;
    language_german: boolean;
    language_english: boolean;
    language_other: boolean;
    work_fully_remote_id: number;
}

interface ContactDetailsModalProps {
    visible: boolean;
    onCancel: () => void;
    onFinish: (values: formValuesType) => void;
    initialValues: initialValuesType;
    isLoading: boolean;
}

export const PreferencesModal: FC<ContactDetailsModalProps> = ({
                                                                   visible,
                                                                   onCancel,
                                                                   onFinish,
                                                                   initialValues,
                                                                   isLoading
                                                               }) => {
    const [form] = Form.useForm();

    const { data: workLoads } = useQuery({ queryKey: ['workLoad'], queryFn: getWorkLoadOptions });

    useEffect(() => {
        const formattedValues = {
            ...initialValues,
            language_german: initialValues.language_german === "true",
            language_english: initialValues.language_english === "true",
            language_other: initialValues.language_other === "true",
        };
        form.setFieldsValue(formattedValues);
    }, [form, initialValues]);

    const handleOk = () => {
        form.submit();
    };

    const workLoadOptions = workLoads?.map((workload) => ({
        value: workload.id,
        label: workload.label
    }));

    return (
        <Modal
            width={600}
            open={visible}
            title="Ihre Kontaktdaten"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Speichern
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item name="work_load_candidate_id" label="Arbeitspensum">
                    <Select options={workLoadOptions} />
                </Form.Item>
                <Form.Item name="work_honorary_id" label="Offen für ehrenamtliche Tätigkeiten">
                    <Radio.Group>
                        <Radio value={1}>Ja</Radio>
                        <Radio value={2}>Nein</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Sprachen (Deutsch, Englisch, Andere)">
                    <Form.Item name="language_german" valuePropName="checked" noStyle>
                        <Checkbox>Deutsch</Checkbox>
                    </Form.Item>
                    <Form.Item name="language_english" valuePropName="checked" noStyle>
                        <Checkbox>Englisch</Checkbox>
                    </Form.Item>
                    <Form.Item name="language_other" valuePropName="checked" noStyle>
                        <Checkbox>Andere</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item name="work_fully_remote_id" label="Offen für reine Homeofficestellen">
                    <Radio.Group>
                        <Radio value={1}>Ja</Radio>
                        <Radio value={2}>Nein</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};
