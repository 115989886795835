import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Input} from 'antd';
import {debounce} from 'lodash';
import {getSearchLocation} from "../services/api";
import {ILocation} from "../services/types";
import {ModalComponent} from "../pages/jobPool/ModalComponent.tsx";
import {defaultWhereOptions} from "../util/utils.ts";
import styled from "styled-components";
import {WhatOption} from "./ProfessionModal.tsx";

interface IAddressModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (value: ILocation | null) => void;
    initialValue?: ILocation | null;
}

export const AddressModal: FC<IAddressModalProps> = ({open, onClose, onSave, initialValue}) => {
    const [whereSearch, setWhereSearch] = useState("");
    const [whereOptions, setWhereOptions] = useState<ILocation[]>(defaultWhereOptions);
    const [whereSelectedOption, setWhereSelectedOption] = useState<ILocation | null>(initialValue ?? null);

    useEffect(()=>{
        setWhereSelectedOption(initialValue as ILocation)
    },[initialValue])

    const debouncedSearch = useMemo(() =>
            debounce((value: string) => {
                if (value) {
                    getSearchLocation(value)
                        .then((data: ILocation[]) => setWhereOptions(data))
                        .catch(error => console.error('Error fetching where options:', error));
                } else {
                    setWhereOptions(defaultWhereOptions);
                }
            }, 300),
        []);

    useEffect(() => {
        debouncedSearch(whereSearch);
    }, [whereSearch, debouncedSearch]);

    const handleSaveWhere = useCallback(() => {
        setWhereOptions(defaultWhereOptions);
        setWhereSearch("");
        onSave(whereSelectedOption);
    }, [onSave, whereSelectedOption]);

    const onWhereSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setWhereSearch(e.target.value);
    }, []);

    const handleSelectWhere = useCallback((option: ILocation) => {
        if (option.label === "Alle Regionen und Postleitzahlen") {
            setWhereSelectedOption({
                ...option,
                radius: undefined,
                region: undefined,
            });
        } else if (option.label === "Homeoffice") {
            setWhereSelectedOption({
                ...option,
                radius: 0,
                region: 2,
            });
        } else {
            setWhereSelectedOption(option);
        }
    }, []);

    return (
        <ModalComponent
            title="Wo suchen Sie?"
            open={open}
            onClose={onClose}
            onSave={handleSaveWhere}
            footerValue={<div style={{textAlign: 'left'}}>{whereSelectedOption?.label || "Alle Standorte, kein Filter"}</div>}
        >
            <WhereWrapper>
                <Input
                    size="large"
                    style={{marginBottom: 8}}
                    placeholder="Suche um Postleitzahl (empfohlen) oder in Region"
                    value={whereSearch}
                    onChange={onWhereSearch}
                />
                {whereOptions.map((option) => (
                    <WhatOption
                        key={option.id}
                        onTouchStart={() => handleSelectWhere(option)}
                        onClick={() => handleSelectWhere(option)}
                        isSelectedOpt={whereSelectedOption?.id === option.id}
                    >
                        {option.label}
                    </WhatOption>
                ))}
            </WhereWrapper>
        </ModalComponent>
    );
};

export const WhereWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    margin-top: 16px;
    @media (max-width: 767px) {
        height: 40vh;
    }
`;

