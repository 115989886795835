import {useContext, useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Input, message, Modal, Popconfirm, Select, Spin} from "antd";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {addOutreach, addOutreachTemplate, getAllOutreachTemplates} from "../../services/api.ts";
import styled from "styled-components";
import {HeartOutlined} from "@ant-design/icons";
import {AuthContext} from "../../context/AuthContext.tsx";
import {format} from "date-fns";
import {IJobDetails, OutreachRequest, OutreachTemplate} from "../../services/types.ts";
import {AlertWrapper} from "../deleteProfile/DeleteProfile.tsx";
import {colors} from "../../util/colors.ts";
import {useMediaQuery} from "@uidotdev/usehooks";

const {TextArea} = Input;

interface IContactCandidateModal {
    isModalOpen: boolean;
    currentJob: IJobDetails
    handleCancel: () => void
    handleOk: () => void
}

export const ContactCandidateModal = ({
                                          handleOk,
                                          isModalOpen,
                                          handleCancel,
                                          currentJob
                                      }: IContactCandidateModal) => {
    const [selectedTemplate, setSelectedTemplate] = useState<OutreachTemplate | null>(null);
    const [outreachMessage, setOutReachMessage] = useState<string>('');
    const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
    const [templateTitle, setTemplateTitle] = useState(() => {
        const currentDate = format(new Date(), 'dd.MM.yyyy');
        return `Vorlage ${currentDate}`;
    });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [isContactShared, setContactShared] = useState(true);

    const {user} = useContext(AuthContext);
    const queryClient = useQueryClient();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const [salutation, setSalutation] = useState<number | null>(null);
    const [firstName, setFirstName] = useState(user?._candidate?.contact.first_name || '');
    const [lastName, setLastName] = useState(user?._candidate?.contact.last_name || '');
    const [phone, setPhone] = useState(user?._candidate?.contact.phone || '');

    useEffect(() => {
        if (user?._candidate) {
            setSalutation(user?._candidate?.contact?._salutation?.label === "Herr" ? 1 : user?._candidate?.contact?._salutation?.label === "Frau" ? 2 : null);
            setFirstName(user!._candidate!.contact.first_name)
            setLastName(user!._candidate!.contact.last_name)
            setPhone(user!._candidate!.contact.phone)
        }
    }, [user])
    const {data: templates = [], isLoading: isLoadingTemplates, error: templatesError} = useQuery<OutreachTemplate[]>({
        queryKey: ['outreachTemplates'],
        queryFn: getAllOutreachTemplates,
    });

    const addTemplateMutation = useMutation({
        mutationFn: addOutreachTemplate,
        onSuccess: () => {
            message.success('Vorlage erfolgreich gespeichert');
            queryClient.invalidateQueries({queryKey: ['outreachTemplates']});
            setSaveTemplateOpen(false);
            setConfirmLoading(false);
            setTemplateTitle('');
        },
        onError: (error) => {
            console.error('Error saving template:', error);
            message.error('Failed to save template');
            setConfirmLoading(false);
        },
    });

    const addOutreachMutation = useMutation({
        mutationFn: addOutreach,
        onSuccess: () => {
            message.success('Nachricht erfolgreich gesendet');
            queryClient.invalidateQueries({queryKey: ['outreaches']});
            handleCancel();
            setOutReachMessage('');
            setSelectedTemplate(null);
        },
        onError: (error) => {
            console.error('Error saving outreach:', error);
            message.error('Failed to save outreach');
        },
    });

    const handleTemplateChange = (value: number) => {
        const template = templates.find(t => t.id === value);
        setOutReachMessage(template?.message || '');
        setSelectedTemplate(template || null);
    };

    const handleSaveTemplate = () => {
        if (!templateTitle.trim()) {
            message.warning('Bitte geben Sie einen Titel für die Vorlage ein');
            return;
        }

        setConfirmLoading(true);
        const newTemplate = {
            name: templateTitle.trim(),
            message: outreachMessage,
            users_id: user?.id
        };

        addTemplateMutation.mutate(newTemplate);
    };

    const handleCancelSaveTemplate = () => {
        setSaveTemplateOpen(false);
        setTemplateTitle('');
    };

    const showSaveTemplatePopconfirm = () => {
        if (!outreachMessage) {
            message.warning('Bitte geben Sie eine Nachricht ein, bevor Sie die Vorlage speichern');
            return;
        }
        setSaveTemplateOpen(true);
    };

    const handleContact = () => {
        let hasError = false;
        if (!outreachMessage) {
            setMessageError(true);
            hasError = true;
        }

        if (!user?.id || hasError) {
            message.warning('Bitte füllen Sie die erforderlichen Felder aus');
            return;
        }

        const outreachData: OutreachRequest = {
            message: outreachMessage,
            candidate_id: user.candidate_id,
            client_id: Number(currentJob.client_id),
            job_demand_id: currentJob.id,
            users_id: user.id,
            show_contact_details: isContactShared ? "on" : "off",
            profile_phone: phone || user._candidate?.contact.phone || "",
            profile_salutation_id: salutation || user._candidate?.contact.salutation_id || undefined,
            profile_first_name: firstName || user._candidate?.contact.first_name || "",
            profile_last_name: lastName || user._candidate?.contact.last_name || "",
            outreach_template_id: selectedTemplate?.id || 0,
        };

        addOutreachMutation.mutate(outreachData);
        handleOk();
    };

    const onContactShare = () => {
        setContactShared(prevState => !prevState);
    };

    const renderAdditionalInputs = () => {
        if (!user?._candidate?.contact.complete || isContactShared) {
            return (
                <AdditionalInputsWrapper>
                    <h3>Erhöhen Sie Ihre Erfolgschancen, indem Sie Ihre Kontaktdaten vervollständigen</h3>
                    <InputGroup>
                        <label>Name:</label>
                        <Select
                            value={salutation === 0 ? null : salutation}
                            placeholder="Anrede"
                            onChange={(value) => setSalutation(value)}
                            style={{width: isMobile ? "100%" : 300}}
                            options={[{value: 1, label: "Herr"}, {value: 2, label: "Frau"}]}
                        />
                        <Input
                            size="large"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Vorname"
                        />
                        <Input
                            size="large"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Nachname"
                        />
                    </InputGroup>
                    <InputGroup>
                        <label>Telefon:</label>
                        <Input
                            size="large"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Telefonnummer"
                        />
                    </InputGroup>
                </AdditionalInputsWrapper>
            );
        }
        return null;
    };

    const isLoading = isLoadingTemplates;
    const hasError = templatesError;
    const isPrivateUser = currentJob?._client?.isPrivate;

    return (
        <Modal
            styles={{
                content: {backgroundColor: colors.bg},
                header: {backgroundColor: colors.bg},
            }}
            style={{top: isMobile ? 5 : "initial"}}
            title="Arbeitgeber kontaktieren"
            width={700}
            open={isModalOpen}
            onCancel={handleCancel}
            onOk={handleContact}
            cancelText="Zurück"
            okText="Kontaktieren"
            okButtonProps={{loading: addOutreachMutation.isPending, disabled: !outreachMessage}}
        >
            {isLoading ? (
                <LoadingWrapper>
                    <Spin size="large"/>
                    <p>Loading...</p>
                </LoadingWrapper>
            ) : hasError ? (
                <ErrorMessage>Error loading data</ErrorMessage>
            ) : (
                <>
                    <TitleHeader>
                        <JobLocPosDetails>
                            <p>Arbeitgebername: {isPrivateUser? "Privat" : currentJob._client.profile_name}</p>
                            {!isPrivateUser && <p>Ansprechpartner: {currentJob?._client?.contact_salutation ?? ""} {currentJob._client.contact_name}</p>}
                        </JobLocPosDetails>
                        <Select
                            placeholder="Nachrichtenvorlagen"
                            onChange={handleTemplateChange}
                            value={selectedTemplate?.id}
                            options={templates.map(template => ({
                                value: template.id,
                                label: template.name
                            }))}
                        />
                    </TitleHeader>
                    <SaveTemplateWrapper>
                        <p>Nachricht</p>
                        <Popconfirm
                            title=""
                            description={
                                <Input
                                    size="large"
                                    placeholder="Enter template title"
                                    value={templateTitle}
                                    maxLength={30}
                                    onChange={(e) => setTemplateTitle(e.target.value)}
                                />
                            }
                            open={saveTemplateOpen}
                            onConfirm={handleSaveTemplate}
                            okButtonProps={{loading: confirmLoading}}
                            onCancel={handleCancelSaveTemplate}
                            okText="Speichern"
                            icon={null}
                            cancelText="Abbrechen"
                        >
                            <Button
                                size="small"
                                icon={<HeartOutlined/>}
                                onTouchStart={showSaveTemplatePopconfirm}
                                onClick={showSaveTemplatePopconfirm}
                            >
                                Als Vorlage speichern
                            </Button>
                        </Popconfirm>
                    </SaveTemplateWrapper>
                    <b>Nachricht an {currentJob?._client?.contact_salutation} {currentJob?._client?.contact_name}</b>
                    <TextArea
                        rows={10}
                        style={{marginBottom: '16px'}}
                        value={outreachMessage}
                        onChange={(e) => {
                            setOutReachMessage(e.target.value);
                            if (e.target.value) setMessageError(false);
                        }}
                        placeholder="Stellen Sie sich hier kurz dem Arbeitgeber vor und treten mit ihm in Kontakt..."
                        status={messageError ? "error" : ""}
                    />
                    <ContactShareWrapper>
                        <Checkbox onChange={onContactShare} checked={isContactShared}>Ihre Kontaktdaten mitsenden</Checkbox>
                    </ContactShareWrapper>
                    {!isContactShared && (
                        <AlertWrapper>
                            <Alert
                                message="Tipp: Geben Sie Ihre vollständigen Kontaktdaten frei, damit der Arbeitgeber möglichst einfach direkt mit Ihnen in Kontakt treten kann."/>
                        </AlertWrapper>
                    )}
                    {isContactShared && renderAdditionalInputs()}
                </>
            )}
        </Modal>
    );
};

const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background-color: ${colors.bg};

    @media (max-width: 767px) {
        flex-wrap: wrap;
        gap: 8px;
    }
`;

const ContactShareWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    p {
        margin: 0;
    }
`;

const SaveTemplateWrapper = styled.div`
    background-color: ${colors.bg};
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;

    p {
        margin: 0;
    }
`;

const JobLocPosDetails = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    p {
        margin: 0;
    }
`;

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
`;

const ErrorMessage = styled.div`
    color: red;
    text-align: center;
    padding: 20px;
`;

const AdditionalInputsWrapper = styled.div`
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
`;

const InputGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;

    label {
        min-width: 80px;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
