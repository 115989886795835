import { destroyCookie, parseCookies, setCookie } from 'nookies'

type CreateSessionCookiesParams = {
    authToken: string
}

export const COOKIE_EXPIRATION_TIME = 60 * 60 * 3
export const TOKEN_COOKIE = 'authToken'

export function createSessionCookies(params: CreateSessionCookiesParams) {
    const { authToken } = params

    if (authToken) {
        setCookie(null, TOKEN_COOKIE, authToken, {
            maxAge: COOKIE_EXPIRATION_TIME,
            path: '/'
        })
    }

}

export function removeSessionCookies() {
    setCookie(null, TOKEN_COOKIE, "", {
        maxAge: 0,
        path: '/'
    })
    destroyCookie(null, TOKEN_COOKIE)
}

export function getToken() {
    const cookies = parseCookies()
    return cookies[TOKEN_COOKIE]
}
