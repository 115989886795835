import {FC, useContext, useState} from 'react';
import {Modal, Radio, Space, Typography} from 'antd';
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {modifyCandExcludePosSurvey, modifyCandidateMatchingPosition,} from "../../../../services/api.ts";
import {AuthContext, User} from "../../../../context/AuthContext.tsx";

const {Title, Paragraph} = Typography;

interface IExcludePositionModal {
    isOpen: boolean;
    onClose: () => void;
    excludingItem: ExcItem
}

export type ExcItem = {
    id: number
    created_at: number
    label: string
    potential_jobs_id: number
    positions_id: number
    matching_keywords: string
    show: number
    category_level: string
    matches: number
    position_group_ids: Array<number>
    potential_jobs_group_ids: Array<number>
    matching_used: string
    match_type_education: string
    match_type_employer: string
    match_type_workexperience: string
    mode: string
}

export const ExcludePositionModal: FC<IExcludePositionModal> = ({isOpen, onClose, excludingItem}) => {
    const {user, setUser} = useContext(AuthContext);

    const [step, setStep] = useState<'confirm' | 'reason'>('confirm');
    const [reason, setReason] = useState<number>(0);
    const queryClient = useQueryClient();

    const updateExcludeMutation = useMutation({
        mutationFn: modifyCandidateMatchingPosition,
        onSuccess: (data) => {
            if (user) {
                setUser({...user, _candidate: {...user._candidate, ...data}} as User);
            }
            queryClient.invalidateQueries({queryKey: ['matchingPositions']});
        },
    });

    const updateExcludeSurveyMutation = useMutation({
        mutationFn: modifyCandExcludePosSurvey,
    });

    const handleExclude = async () => {
        const usedValue = excludingItem.matching_used;
        await updateExcludeMutation.mutateAsync({
            candidateId: user?._candidate?.id ?? 0,
            position_id: excludingItem.id,
            matching_used: usedValue !== "true"
        })
        if (usedValue === "false") {
            onClose()
            setStep('confirm');
        }
        setStep('reason');
    };

    const handleSave = async () => {
        if (user && user._candidate) {
            await updateExcludeSurveyMutation.mutateAsync({
                response: reason,
                users_id: user.id,
                candidateId: user._candidate.id,
                positions_id: excludingItem.id,
                candidate_matching_position_id: excludingItem.positions_id
            });
        } else {
            console.error('User or user._candidate is undefined');
            // Handle the error appropriately, maybe show a message to the user
        }
        setStep('confirm');
        onClose();
    };

    const handleCancel = () => {
        setStep('confirm');
        setReason(0);
        onClose();
    };

    const getModalTitle = () => {
        if (step === "confirm") {
            if (excludingItem.matching_used === "true") {
                return "Wollen Sie diese Tätigkeit nicht mehr im Matching verwenden?"
            }
            return "Wollen Sie diese Tätigkeit wieder im Matching verwenden?"
        }
        return "Die Tätigkeit wurde entfernt"
    }

    return (
        <Modal
            open={isOpen}
            onCancel={handleCancel}
            width={600}
            onOk={step === 'confirm' ? handleExclude : handleSave}
            okText="Speichern"
            cancelText="Abbrechen"
            okButtonProps={{
                loading: updateExcludeMutation.isPending || updateExcludeSurveyMutation.isPending
            }}
            title={getModalTitle()}
        >
            {step === 'confirm' ? (
                <div className="p-6">
                    <Paragraph className="mb-8">
                        {excludingItem.matching_used !== "true" ? "Hinweis: Sie bekommen wieder Jobvorschläge für Jobs mit dieser Tätigkeit und ihr anonymes Kurzprofil wird Arbeitgebern für diese Tätigkeit angezeigt." : "Hinweis: Sie bekommen keine Jobvorschläge mehr für Jobs mit dieser Tätigkeit und ihr anonymes Kurzprofil wird Arbeitgebern für diese Tätigkeit nicht mehr angezeigt."}
                    </Paragraph>
                </div>
            ) : (
                <div className="p-6">
                    <Space direction="vertical" size="large" className="w-full">
                        <Title level={5}>Warum haben Sie diese Tätigkeit entfernt?</Title>
                        <Radio.Group onChange={(e) => setReason(e.target.value)} value={reason}>
                            <Space direction="vertical">
                                <Radio value={1}>Keine Angabe</Radio>
                                <Radio value={2}>
                                    Ich möchte nicht mehr in dieser Tätigkeit arbeiten
                                </Radio>
                                <Radio value={3}>
                                    Bei der Zuordnung dieser Tätigkeit muss es sich um einen Fehler handeln, sie macht
                                    keinen Sinn
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Space>
                </div>
            )}
        </Modal>
    );
};
