import { useCallback, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

interface Coordinates {
    lat: number;
    lng: number;
}

const API_KEY = "AIzaSyCjTa5qlQMltK8-KuErnOJvN7yJMsEPr9c";

const mapContainerStyle = {
    width: '100%',
    height: '300px',
    marginTop: 8,
};

export const GeocodedMap = ({ address }: { address?: string }) => {
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
    const [error, setError] = useState<string | null>(null);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: API_KEY,
        libraries: ["places"],
    });

    const geocode = useCallback(() => {
        if (!address) return;

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results && results[0] && results[0].geometry && results[0].geometry.location) {
                setCoordinates({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                });
            } else {
                setError("Geocoding failed. Please check the address and try again.");
            }
        });
    }, [address]);

    useEffect(() => {
        if (isLoaded) {
            geocode();
        }
    }, [isLoaded, geocode]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div style={mapContainerStyle}>
            {coordinates ? (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={13}
                    center={coordinates}
                    options={{ gestureHandling: 'cooperative' }}
                >
                    <MarkerF position={coordinates} title={address} />
                </GoogleMap>
            ) : <div>Loading map...</div>}
        </div>
    );
};
