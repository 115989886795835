import {createContext, ReactNode, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {AxiosError} from 'axios'
import {createSessionCookies, getToken, removeSessionCookies} from "../util/tokenCookies"
import {authApi, getCandidateDetails, getUserApi, getUserDetails, loginUserApi, signupUserApi} from "../services/api"
import {setAuthorizationHeader} from "../services/interceptors"
import {IClientProfile} from "../services/types"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

type Props = {
    children: ReactNode
}

export type User = {
    id: number
    email: string
    role: string
    created_at: number
    name: string
    sessionId: string
    recreated_at: string
    pd_person_id: string
    pd_deal_id: string
    client_id: number
    users_id_label: string
    client_role: string
    hash_md5: string
    last_authtoken: number
    last_logout: string
    plan_type: string
    outreaches_allowed: string
    subscriptions_id: number
    outreaches_current_month: number
    status: string
    phone: string
    plan_label: string
    outreaches_allowed__monthly: number
    sendgrid_id: string
    candidate_id: number
    display_plan_label: string
    jobs_allowed: string
    jobs_current_month: number
    jobs_allowed_monthly: number
    mode: string
    key: string
    magic_link: {
        token: string
        expiration: number
        used: boolean
    },
    _candidate?: IClientProfile
}

export type SignInCredentials = {
    email: string
    password: string
}

export type SignUpCredentials = {
    email: string
    password: string
    telephone: string
}

export type AuthContextData = {
    user?: User
    isAuthenticated: boolean
    loadingUserData: boolean
    signIn: (credentials: SignInCredentials | { token: string }) => Promise<void | AxiosError>
    signUp: (credentials: SignUpCredentials) => Promise<void | AxiosError>
    signOut: () => void
    error: string | null
    setError: (st: string) => void
    setUser: (user: User) => void
}

export const AuthContext = createContext({} as AuthContextData)

export const AuthProvider = ({children}: Props) => {
    const [user, setUser] = useState<User | undefined>();
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const queryClient = useQueryClient();

    const token = getToken();
    const isAuthenticated = Boolean(token);

    const {
        data: userData,
        isLoading: loadingUserData,
    } = useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            if (!token) return null

            setAuthorizationHeader({request: authApi.defaults, token});
            const response = await getUserApi();

            if (response) {
                const {id} = response;
                const moreDetails = await getUserDetails(id)
                const candidateDetails = await getCandidateDetails()

                const userDetails = {
                    id,
                    ...moreDetails,
                    _candidate: candidateDetails
                }
                setUser(userDetails as never);
            }

            return null;
        },
        enabled: !!token,
    });

    useEffect(() => {
        if (userData) {
            setUser(userData);
        }
    }, [userData]);

    // Sign In Mutation
    const signInMutation = useMutation({
        mutationFn: async (params: SignInCredentials | { token: string }) => {
            let authToken: string;

            if ('email' in params && 'password' in params) {
                const {email, password} = params;
                const response = await loginUserApi(email, password);
                authToken = response.authToken;
            } else {
                authToken = params.token;
            }

            createSessionCookies({authToken});
            setAuthorizationHeader({request: authApi.defaults, token: authToken});
            return authToken;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['user']});
            setError(null)
        },
    });

    // Sign Up Mutation
    const signUpMutation = useMutation({
        mutationFn: async ({email, password, telephone}: SignUpCredentials) => {
            const response = await signupUserApi(email, password, telephone);
            return response.authToken;
        },
        onSuccess: (authToken) => {
            createSessionCookies({authToken});
            setAuthorizationHeader({request: authApi.defaults, token: authToken});
            queryClient.invalidateQueries({queryKey: ['user']});
        },
    });

    // Authentication methods
    const signIn = async (params: SignInCredentials | { token: string }) => {
        try {
            await signInMutation.mutateAsync(params);
        } catch (error) {
            throw error as AxiosError;
        }
    };

    const signUp = async (params: SignUpCredentials) => {
        try {
            await signUpMutation.mutateAsync(params);
        } catch (error) {
            throw error as AxiosError;
        }
    };

    const signOut = () => {
        localStorage.removeItem('appRedirectPath')
        removeSessionCookies();
        setUser(undefined);
        queryClient.clear();
        navigate('/');
    };

    useEffect(() => {
        if (!token) {
            removeSessionCookies();
            setUser(undefined);
        }
    }, [navigate, pathname, token]);

    const contextValue: AuthContextData = {
        isAuthenticated,
        user,
        setUser,
        loadingUserData,
        signIn,
        signOut,
        signUp,
        setError,
        error
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
