import {useCallback, useContext, useLayoutEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Card, Col, Input, List, Row, Typography} from 'antd';
import {formatDistanceToNow} from "date-fns";
import {de} from 'date-fns/locale'
import {getCandidateFromMsgByIdApi, getCandidateMessagesByComId, sendMessageApi} from "../../services/api.ts";
import {IMessage, IMessageBuble} from "../../services/types.ts";
import {PageTitle} from "../../components/PageTitle.tsx";
import styled from "styled-components";
import {Loader} from "../../components/Loader.tsx";
import {AuthContext} from "../../context/AuthContext.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {InfoRowWrapper} from "../profile/subPages/Education.tsx";

const {Text} = Typography;
const {TextArea} = Input;

type FirstMessageType = Omit<IMessage, '_candidate_2_0' | '_client' | '_job_demand_with_labels'>;

type CombinedMessageType = IMessageBuble | FirstMessageType;

export const InfoRow = ({label, value}: { label: string; value?: string }) => (
    <InfoRowWrapper>
        <p><b>{label}:</b></p>
        <p>{value || "n/a"}</p>
    </InfoRowWrapper>
);
export const MessageDetail = () => {
    const {user} = useContext(AuthContext);
    const [newMessage, setNewMessage] = useState("");
    const navigate = useNavigate();
    const {id = ""} = useParams<{ id: string }>();
    const messageListRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const queryClient = useQueryClient();


    const {data, isLoading, error}: UseQueryResult<IMessage, Error> = useQuery({
        queryKey: ['candidate-det', id],
        queryFn: () => getCandidateFromMsgByIdApi(id),
        enabled: !!id,
    });

    const msgDetails = data;

    const {
        data: messages,
        isLoading: isMessagesLoading,
    }: UseQueryResult<IMessageBuble[], Error> = useQuery({
        queryKey: ['message', msgDetails?.id],
        queryFn: () => getCandidateMessagesByComId(msgDetails!.id),
        enabled: !!msgDetails?.id,
    });

    const sendMessageMutation = useMutation({
        mutationFn: sendMessageApi,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['message', msgDetails!.id]});
            setNewMessage("");
        }
    });

    const handleSendMessage = () => {
        if (!newMessage.trim() || !msgDetails) return;

        sendMessageMutation.mutate({
            message: newMessage,
            type: "outreach", // Assuming this is an outreach message
            client_id: msgDetails.client_id,
            candidate_id: msgDetails.candidate_id,
            job_demand_id: msgDetails._job_demand_with_labels[0]?.id || 0,
            communication_id: msgDetails.id,
            contact_data: msgDetails.contact_data,
        });
        setNewMessage("")
        scrollToBottom();
    };

    const scrollToBottom = useCallback(() => {
        if (messageListRef.current) {
            const firstChildDiv = messageListRef.current.querySelector('div');
            if (firstChildDiv) {
                firstChildDiv.scrollTop = firstChildDiv.scrollHeight;
            }
        }
    }, []);

    useLayoutEffect(() => {
        scrollToBottom();
    }, [messages, scrollToBottom]);

    if (isLoading) return <Loader/>;
    if (error) return <Alert message="Error" description={error.message} type="error"/>;
    if (!msgDetails) return <Alert message="No data available" type="warning"/>;
    const {_client: client, _job_demand_with_labels: jobDemand, ...firstMessage} = msgDetails
    const messageList: CombinedMessageType[] = messages ? [...messages, firstMessage].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()) : [];

    const mainJobItems = [
        {label: 'Arbeitgeber', value: client?.profile_name??""},
        {label: 'Ansprechpartner', value: `${client?.salutation_label ??" "}${client?.contact_name ?? ""}`},
        {label: 'Stellentyp', value: jobDemand![0]?.job_type_label ?? ""},
        {label: 'Mindestens benötigtes Arbeitspensum', value: jobDemand![0]?.work_load_label ?? ""},
        {label: 'Suchort', value: jobDemand![0]?.search_location_label ?? ""}
    ];

    return (
        <div>
            <PageTitle
                mainBtnType="default"
                mainBtnText={jobDemand[0] ? "zur Stelle" : undefined}
                onMainBtnClick={jobDemand[0] ? () => navigate(`/jobs/${jobDemand[0]?.id}`) : undefined}
                title={msgDetails.type === "outreach" ? "Direktansprache eines Kandidaten" : "Bewerbung auf eine Stellenanzeige"}/>
            <div className="full-height-container">
                <Row gutter={24} className="full-height-row">
                    <Col span={isMobile ? 24 : 12}>
                        <Card title="Gesprächsverlauf">
                            <div ref={messageListRef}>
                                <ListStyled
                                    loading={isMessagesLoading}
                                    dataSource={messageList}
                                    renderItem={(msg: CombinedMessageType) => (
                                        <List.Item style={{
                                            display: 'flex',
                                            marginRight: 24,
                                            justifyContent: ('sending_users_id' in msg && msg.sending_users_id === user!.id) || msg.type==="application" ? "flex-end" : 'flex-start'
                                        }}>
                                            <Card style={{
                                                width: '90%',
                                                backgroundColor: ('sending_users_id' in msg && msg.sending_users_id === user!.id) || msg.type==="application" ? "#e2eeff" : 'white'
                                            }}>
                                                <MessageText>{msg.message}</MessageText>
                                                <MessageDate>{formatDistanceToNow(new Date(msg.created_at), {
                                                    addSuffix: true,
                                                    locale: de
                                                })}</MessageDate>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </div>
                            <MessageAreaBtnWrapper>
                                <TextArea rows={4} placeholder="Geben Sie hier Ihre Nachricht ein..."
                                          style={{marginBottom: '8px', backgroundColor: 'white'}} value={newMessage}
                                          onChange={(e) => setNewMessage(e.target.value)} disabled={!jobDemand[0]}/>
                                <Button type="primary" onClick={handleSendMessage}
                                        loading={sendMessageMutation.isPending} disabled={!jobDemand[0]}>Nachricht
                                    senden</Button>
                            </MessageAreaBtnWrapper>
                            <div style={{marginTop: '24px'}}>
                                <Text>{msgDetails.contact_data === "visible" ? "Der Bewerber hat seine Kontaktdaten, soweit im Kandidatenprofil gepflegt, freigegeben." : "Der Bewerber hat seine Kontaktdaten leider nicht freigegeben."}</Text>
                            </div>
                        </Card>
                    </Col>

                    <Col span={isMobile ? 24 : 12}>
                        <Card title={jobDemand[0]?.title ?? "Stelle: Es wurde keine Stelle angehängt oder sie wurde deaktiviert"} style={{marginBottom: '24px', backgroundColor: '#f8f9fb'}}>
                            {!!msgDetails?.contact_data && msgDetails?.contact_data!=="anonymous" && mainJobItems.filter(item => !!item.value).map(item => {
                                return <InfoRow label={item.label} value={item.value}/>
                            })}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const MessageText = styled.p`
    margin: 0;
`;
const MessageDate = styled.p`
    margin: 0;
    font-weight: 300;
    font-size: 11px;
    color: #424242;
`;

const MessageAreaBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
`;

export const ListStyled = styled(List<CombinedMessageType>)`
    height: calc(100vh - 440px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;
