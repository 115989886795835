import {Spin} from "antd";
import styled from "styled-components";
import {useEffect} from "react";

export const EmptyDivFullWidth = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const Loader = () => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            localStorage.removeItem('redirectPath');
        }, 4000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <EmptyDivFullWidth>
            <Spin size="large" />
        </EmptyDivFullWidth>
    );
}
