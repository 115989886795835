import {getJobPool, getJobPoolCount} from "../../../services/api";
import {IJobDetails, ILocation, IPosition} from "../../../services/types";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";


export const useJobPool = (limit: number, positionId?: number, locationId?: number) => {
    return useInfiniteQuery<IJobDetails[], Error>({
        initialData: undefined,
        initialPageParam: undefined,
        queryKey: ['jobPool', positionId || 'all', locationId || 'all'],
        queryFn: ({pageParam = 0}) => getJobPool({
            limit,
            offset: pageParam as number,
            positionId,
            locationId
        }),
        getNextPageParam: (lastPage, allPages) => {
            const nextOffset = allPages.length * limit;
            return lastPage.length === limit ? nextOffset : undefined;

        },
        staleTime: 5 * 60 * 1000,
    });
};

export const useJobPoolCount = (position?: IPosition, location?: ILocation) => {
    return useQuery({
        queryKey: ['candidateCount', position?.id || "all", location?.id || "all"],
        queryFn: () => getJobPoolCount(position, location)
    });
};
