import { Spin } from "antd";
import { EmptyDivFullWidth } from "../pages/messageDetail/MessageDetail.tsx";
import { useEffect } from "react";

export const Loader = () => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            localStorage.removeItem('redirectPath');
        }, 4000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <EmptyDivFullWidth>
            <Spin size="large" />
        </EmptyDivFullWidth>
    );
}
