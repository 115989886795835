import React, { useContext, useState } from 'react';
import { Button, Card, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { AuthContext, User } from "../../../context/AuthContext";
import { PageTitle } from "../../../components/PageTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCandidate } from "../../../services/api";
import { MainJobModal } from "./components/MainJobModal";
import { LastEmployerModal } from "./components/LastEmployerModal";
import { DeepPartialCandidateProfile } from "../../../services/types";
import styled from "styled-components";

const { Title, Text } = Typography;

const placeholderValues: Record<string, string> = {
    "Tätigkeitsbereich": "keine Angabe, bitte ergänzen Sie Ihren Haupttätigkeitsbereich",
    "Berufsbezeichnung in diesem Bereich": "keine Angabe, bitte ergänzen Sie Ihre Berufsbezeichnung",
    "Berufserfahrung in diesem Bereich": "keine Angabe, bitte ergänzen Sie Ihre Berufserfahrung",
    "Name": "keine Angabe, bitte ergänzen Sie, wenn möglich",
    "Ihre wichtigsten Tätigkeiten": "keine Angaben, bitte ergänzen Sie, wenn möglich",
    "Verweildauer": "keine Angabe, bitte ergänzen Sie, wenn möglich"
};

export const SectionTitle = ({ title, onEdit }: { title: string; onEdit?: () => void }) => (
    <SectionTitleWrapper>
        <StyledTitle level={4}>{title}</StyledTitle>
        {!!onEdit && <EditButton icon={<EditOutlined />} type="text" onClick={onEdit} />}
    </SectionTitleWrapper>
);

const InfoRow = ({ label, value }: { label: string; value?: string }) => (
    <InfoRowWrapper>
        <StyledLabel>{label}:</StyledLabel>
        <StyledValue>{value || placeholderValues[label as keyof typeof placeholderValues]}</StyledValue>
    </InfoRowWrapper>
);

export const WorkHistory: React.FC = () => {
    const { user, setUser } = useContext(AuthContext);
    const [mainJobModalVisible, setMainJobModalVisible] = useState(false);
    const [lastEmployerModalVisible, setLastEmployerModalVisible] = useState(false);
    const [previousEmployerModalVisible, setPreviousEmployerModalVisible] = useState(false);
    const [selectedSector, setSelectedSector] = useState<string | null>(null);
    const queryClient = useQueryClient();

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({ ...user, _candidate: { ...user!._candidate, ...data } } as User);
            queryClient.invalidateQueries({ queryKey: ['candidate'] });
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    return (
        <ResponsiveSpace direction="vertical" size="large">
            <PageTitle title="Letzte Arbeitgeber" />

            <StyledCard
                title={<SectionTitle title="Tätigkeitsbereich mit meister Berufserfahrung" onEdit={() => setMainJobModalVisible(true)} />}
            >
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Tätigkeitsbereich" value={user?._candidate?.main_job.subsector} />
                    <InfoRow label="Berufsbezeichnung in diesem Bereich" value={user?._candidate?.main_job.job_position} />
                    <InfoRow label="Berufserfahrung in diesem Bereich" value={user?._candidate?.main_job.job_experience} />
                </Space>
            </StyledCard>

            <StyledCard
                title={<SectionTitle title="Letzter Arbeitgeber" onEdit={() => setLastEmployerModalVisible(true)} />}
            >
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Name" value={user?._candidate?.display.last_employer.employer_name} />
                    <InfoRow label="Ihre wichtigsten Tätigkeiten" value={user?._candidate?.display.last_employer.employer_department} />
                    <InfoRow label="Verweildauer" value={user?._candidate?.display.last_employer.employer_years} />
                </Space>
            </StyledCard>

            <StyledCard
                title={<SectionTitle title="Vorletzter Arbeitgeber" onEdit={() => setPreviousEmployerModalVisible(true)} />}
            >
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Name" value={user?._candidate?.display.previous_employer.employer_name} />
                    <InfoRow label="Ihre wichtigsten Tätigkeiten" value={user?._candidate?.display.previous_employer.employer_department} />
                    <InfoRow label="Verweildauer" value={user?._candidate?.display.previous_employer.employer_years} />
                </Space>
            </StyledCard>

            {mainJobModalVisible && (
                <MainJobModal
                    visible={mainJobModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setMainJobModalVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                main_job: values,
                                ops: { mode: "main_job_update" },
                            } as DeepPartialCandidateProfile
                        })
                        setMainJobModalVisible(false);
                    }}
                    initialValues={user!._candidate!.main_job}
                    selectedSector={selectedSector}
                    setSelectedSector={setSelectedSector}
                />
            )}
            {lastEmployerModalVisible && (
                <LastEmployerModal
                    visible={lastEmployerModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setLastEmployerModalVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                display: { last_employer: values },
                                ops: { mode: "last_employer_update" },
                            } as DeepPartialCandidateProfile
                        })
                        setLastEmployerModalVisible(false);
                    }}
                    initialValues={user!._candidate!.display.last_employer}
                />
            )}
            {previousEmployerModalVisible && (
                <LastEmployerModal
                    visible={previousEmployerModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setPreviousEmployerModalVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                display: { previous_employer: values },
                                ops: { mode: "previous_employer_update" },
                            } as DeepPartialCandidateProfile
                        })
                        setPreviousEmployerModalVisible(false);
                    }}
                    initialValues={user!._candidate!.display.previous_employer}
                />
            )}
        </ResponsiveSpace>
    );
};

const ResponsiveSpace = styled(Space)`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
`;

const StyledCard = styled(Card)`
  width: 100%;
  @media (max-width: 576px) {
    .ant-card-head-title {
      padding: 16px 0 0;
    }
    .ant-card-body {
      padding: 16px;
    }
  }
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 576px) {
        align-items: flex-start;

        .ant-typography {
            margin-bottom: 8px;
        }
    }
`;

const InfoRowWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 4px;
  }
`;

const StyledLabel = styled(Text)`
  font-weight: bold;
  min-width: 120px;
  
  @media (max-width: 576px) {
    min-width: unset;
  }
`;

const StyledValue = styled(Text)`
  flex: 1;
`;

const StyledTitle = styled(Title)`
    margin-right: 8px;
    min-width: 0;
    word-wrap: break-word;

    @media (max-width: 576px) {
        margin-bottom: 8px;
        white-space: pre-wrap;
    }
`;
const EditButton = styled(Button)`
`;
