import {FC, useContext, useState} from 'react';
import {Card, Space, Typography} from 'antd';
import {AuthContext, User} from "../../../context/AuthContext.tsx";
import {PageTitle} from "../../../components/PageTitle.tsx";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateCandidate} from "../../../services/api.ts";
import {ContactDetailsModal} from "./components/ContactDetailsModal.tsx";
import {PreferencesModal} from "./components/PreferencesModal.tsx";
import {DeepPartialCandidateProfile} from "../../../services/types.ts";
import {SectionTitle} from "./WorkHistory.tsx";
import {InfoRowWrapper} from "./Education.tsx";
import {showNotification} from "../../../components/Notification.tsx";
import { AxiosError } from 'axios';

const {Text} = Typography;

const placeholderValues: Record<string, string> = {
    "Ansprache": "keine Angabe, bitte ergänzen Sie die Ansprache",
    "Vorname": "keine Angabe, bitte ergänzen Sie Ihren Vornamen",
    "Nachname": "keine Angabe, bitte ergänzen Sie Ihren Nachnamen",
    "Telefonnummer": "keine Angabe, bitte ergänzen Sie Ihre Telefonnummer",
    "Email": "keine Angabe, bitte ergänzen Sie Ihre Email",
    "Arbeitspensum": "keine Angabe, bitte ergänzen Sie Ihr mögliches Arbeitspensum",
    "Ehrenamtliche Tätigkeiten": "keine Angabe, bitte ergänzen Sie, ob Sie offen für ehrenamtliche Tätigkeiten sind",
    "Sprachen": "keine Angabe, bitte ergänzen Sie Ihre Sprachen",
    "Reine Homeofficestellen": "Nein"
};

const InfoRow = ({label, value}: { label: string; value?: string }) => (
    <InfoRowWrapper>
        <Text strong>{label}:</Text>
        <Text>{value || placeholderValues[label]}</Text>
    </InfoRowWrapper>
);

export const Personal: FC = () => {
    const {user, setUser} = useContext(AuthContext);
    const [contactDetailsModalVisible, setContactDetailsModalVisible] = useState(false);
    const [preferencesModalVisible, setPreferencesModalVisible] = useState(false);
    const queryClient = useQueryClient();

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({...user, _candidate: {...user!._candidate, ...data}} as User);
            queryClient.invalidateQueries({queryKey: ['candidate']});
        },
        onError:(er: AxiosError)=>{
            if (er.response) {
                const errorData = er.response.data as { payload: { param: string }; message: string }; // Type assertion
                showNotification('error', errorData.payload.param, errorData.message);
            } else {
                showNotification('error', 'Unknown error', 'An unexpected error occurred.');
            }
        }
    });

    return (
        <Space direction="vertical" size="large" className="w-full">
            <PageTitle title="Persönliches"/>

            <Card title={<SectionTitle title="Kontaktdaten"
                                       onEdit={() => setContactDetailsModalVisible(true)}/>}>
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Ansprache"
                             value={user?._candidate?.contact?.salutation_id ? user?._candidate?.contact?.salutation_id === 1 ? "Herr" : "Frau" : ""}/>
                    <InfoRow label="Vorname" value={user?._candidate?.contact.first_name}/>
                    <InfoRow label="Nachname" value={user?._candidate?.contact.last_name}/>
                    <InfoRow label="Telefonnummer" value={user?._candidate?.contact.phone}/>
                    <InfoRow label="Email" value={user?._candidate?.contact.email}/>

                </Space>
            </Card>

            <Card title={<SectionTitle title="Persönliche Eigenschaften und Präferenzen"
                                       onEdit={() => setPreferencesModalVisible(true)}/>}>
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Arbeitspensum" value={user?._candidate?.preferences.work_type}/>
                    <InfoRow label="Ehrenamtliche Tätigkeiten" value={user?._candidate?.preferences.work_honorary}/>
                    <InfoRow label="Sprachen" value={user?._candidate?.additional.languages}/>
                    <InfoRow label="Reine Homeofficestellen"
                             value={user?._candidate?.preferences.work_fully_remote_id === 2 ? "Nein" : "Ja"}/>
                </Space>
            </Card>

            {contactDetailsModalVisible && (
                <ContactDetailsModal
                    visible={contactDetailsModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setContactDetailsModalVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                contact: values,
                                ops: {mode: "contact_data_update"},
                            } as DeepPartialCandidateProfile
                        })
                        setContactDetailsModalVisible(false);
                    }}
                    initialValues={user!._candidate!.contact}
                />
            )}
            {preferencesModalVisible && (
                <PreferencesModal
                    visible={preferencesModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setPreferencesModalVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                additional: {
                                    language_other: values.language_other.toString(),
                                    language_german: values.language_german.toString(),
                                    language_english: values.language_english.toString()
                                },
                                preferences: {
                                    work_honorary_id: values.work_honorary_id,
                                    work_fully_remote_id: values.work_fully_remote_id,
                                    work_load_candidate_id: values.work_load_candidate_id
                                },
                                ops: {mode: "personal_preferences_update"},
                            } as DeepPartialCandidateProfile
                        })
                        setPreferencesModalVisible(false);
                    }}
                    initialValues={{...user!._candidate!.preferences, ...user!._candidate!.additional}}
                />
            )}
        </Space>
    );
};


