import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import React from "react";

Bugsnag.start({
    apiKey: '1cca5de39c4511d925cbb4e072347041',
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_ENVIRONMENT,
})

BugsnagPerformance.start({ apiKey: '1cca5de39c4511d925cbb4e072347041' })

export const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
