import {Button} from "antd";
import {MessageOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {useMediaQuery} from "@uidotdev/usehooks";
import {Link, useNavigate} from "react-router-dom";
import {IJobDetails} from "../../../services/types.ts";
import {colors} from "../../../util/colors.ts";

export const JobPoolItem = ({item, isJobSearchAutomatic}: { item: IJobDetails, isJobSearchAutomatic: boolean }) => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    return (
        <RowItemWrapper onClick={() => isMobile && navigate(`/jobs/${item.id}`)}>
            <BgWrapper>
                <ItemDetails>
                    <InfoContainer>
                        <Link to={`/jobs/${item.id}`} style={{color: 'black'}}><h3
                            style={{width: isMobile ? "fit-content" : "70%"}}>{item.title}</h3></Link>
                        {item?.search_location_label &&
                            <PoolItemMeta>Ort: {item.search_location_label}</PoolItemMeta>}
                        {item?.work_load_label &&
                            <PoolItemMeta>Arbeitspensum: {item.work_load_label}</PoolItemMeta>}
                        {item?.homeoffice_id > 1 &&
                            <PoolItemMeta>Homeoffice: {item.homeoffice_label}</PoolItemMeta>}
                        {item?._client?.profile_name &&
                            <PoolItemMeta>Arbeitgeber: {item._client?.profile_name}</PoolItemMeta>}
                        {item?.potential_jobs_label && isJobSearchAutomatic &&
                            <PoolItemMeta><b>Match über Ihre vorstellbare
                                Tätigkeit: {item.potential_jobs_label}</b></PoolItemMeta>}
                    </InfoContainer>
                </ItemDetails>
                <ItemBtns>
                    {!isMobile && <Button
                        onClick={() => navigate(`/jobs/${item.id}`)}
                        shape="round"
                        style={{width: 200}}
                        type="primary"
                    >
                        Ansehen
                    </Button>}
                    {item._outreaches_to_candidate && (
                        <CandOutreached>
                            <MessageOutlined/>
                            <p>Kontaktiert</p>
                        </CandOutreached>
                    )}
                </ItemBtns>
            </BgWrapper>
        </RowItemWrapper>
    )
}

export const RowItemWrapper = styled.div`
    background: ${colors.bg};
    padding: 0 92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0;
        border-bottom: none;
    }
`
export const BgWrapper = styled.div`
    width: 100%;
    padding: 8px;
    min-height: 100px;
    background: white !important;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
        background: ${colors.bg} !important;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0;
        border-bottom: none;
    }
`

export const ItemDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 70%;
    justify-content: space-between;
    @media (max-width: 767px) {
        align-items: flex-start;
        flex-direction: column;
        width: calc(100% - 4px);
        padding: 8px;
        box-shadow: 0 .125rem .25rem rgba(45, 45, 45, .12), 0 .0625rem .1875rem rgba(45, 45, 45, .16), 0 0 .125rem rgba(45, 45, 45, .2);
        background: white;
        border-radius: 4px;
        margin: 0 2px;
    }
`

export const InfoContainer = styled.div`
    flex: 1;
    min-width: 0;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }
`

export const ItemBtns = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    gap: 8px;
    flex-shrink: 0;
    @media (max-width: 767px) {
        padding-top: 3px;
        margin: 0;
        width: 100%;
        align-items: center;
    }
`

export const PoolItemMeta = styled.p`
    margin: 0 0 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #696969
`;

const CandOutreached = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    p {
        margin: 0;
    }
`
