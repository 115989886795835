import axios, {AxiosInstance, AxiosResponse, CreateAxiosDefaults, RawAxiosRequestHeaders} from 'axios'

import {setupInterceptors} from './interceptors'
import {
    CandidateCountParams,
    CandidatePoolParams,
    CandidatePoolType,
    CompanySizeType,
    ICandidate,
    IClientProfile,
    IJobDemand,
    IJobDetails,
    ILocation,
    IMessage,
    IMessageBuble,
    IndustrySector,
    IndustrySubsector,
    IPosition,
    IPositions,
    ISearchResult,
    JobTypeOption,
    Locations,
    OutreachRequest,
    OutreachResponse,
    OutreachTemplate,
    OutreachTemplateRequest,
    OutreachTemplateResponse
} from "./types.ts";
import {User} from "../context/AuthContext.tsx";
import {getToken} from "../util/tokenCookies.ts";
import {notification} from "antd";
import {IAlertConfig} from "candidate/src/services/types.ts";

const getHeaders = (): RawAxiosRequestHeaders => {
    const headers: RawAxiosRequestHeaders = {};

    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
        headers["X-Data-Source"] = "staging";
    }

    return headers;
};

const authConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_AUTH_URL as string,
    headers: getHeaders()
};

const baseConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_BASE_URL as string,
    headers: getHeaders()
};

const billingConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_BILLING_URL as string,
    headers: getHeaders()
};

const privateBillingConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_PRIVATE_BILLING_URL as string,
    headers: getHeaders()
};

const privacyConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_PRIVACY_URL as string,
    headers: getHeaders()
};

export const authApi: AxiosInstance = setupInterceptors(axios.create(authConfig))
export const baseApi: AxiosInstance = setupInterceptors(axios.create(baseConfig))
export const billingApi: AxiosInstance = setupInterceptors(axios.create(billingConfig))
export const privateBillingApi: AxiosInstance = setupInterceptors(axios.create(privateBillingConfig))
export const privacyApi: AxiosInstance = setupInterceptors(axios.create(privacyConfig))

export const getUserApi = async () => {
    const {data} = await authApi.get('/auth/me')
    return data
}

export const loginUserApi = async (email: string, password: string) => {
    try {
        const {data} = await authApi.post('/auth/login', {email, password});
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorMessage = error.response?.data?.message || 'Bei der Anmeldung ist ein Fehler aufgetreten';

            notification.error({
                message: 'Anmeldung fehlgeschlagen',
                description: errorMessage,
            });
        } else {
            notification.error({
                message: 'Anmeldung fehlgeschlagen',
                description: 'Bei der Anmeldung ist ein Fehler aufgetreten',
            });
        }
        throw error;
    }
};

export const changeUserEmailApi = async (newEmail: string, currentUserData: User) => {
    const updatedUserData = {
        id: currentUserData.id,
        mode: "change_email",
        email: newEmail,
    };

    const {data}: { data: User } = await authApi.post(`/users/${updatedUserData.id}`, updatedUserData);
    return data;
};

export const signupUserApi = async (email: string, password: string, telephone: string, isPrivate: boolean) => {
    try {
        const {data} = await authApi.post('/auth/signup-new', {
            email,
            password,
            role: "client",
            phone: telephone,
            isPrivate
        });
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorMessage = error.response?.data?.message || 'Bei der Registrierung ist ein Fehler aufgetreten';

            notification.error({
                message: 'Registrierung fehlgeschlagen',
                description: errorMessage,
            });
        } else {
            notification.error({
                message: 'Registrierung fehlgeschlagen',
                description: 'Ein unerwarteter Fehler ist aufgetreten',
            });
        }
        throw error;
    }
};

export const getCandidatePool = async ({limit, offset, position, location}: {
    limit: number,
    offset: number,
    position?: IPosition,
    location?: ILocation
}) => {
    const params: CandidatePoolParams = {
        limit,
        offset,
        mode: "lean",
    };

    if (position) {
        params.search_what_id = position.id;
    }

    if (location) {
        params.search_location_id = location.id;
    }

    const {data}: { data: CandidatePoolType } = await baseApi.get('/candidate', {params});
    return data;
};

export const getCandidateCount = async (position?: IPosition, location?: ILocation) => {
    const params: CandidateCountParams = {
        mode: "lean_count",
    };
    if (position) {
        params.search_what_id = position.id;
    }

    if (location) {
        params.search_location_id = location.id;
    }

    const {data}: { data: { id: number } } = await baseApi.get('/candidate', {
        params
    });
    return data;
};

export const getSearchWhat = async (): Promise<IPositions> => {
    const {data}: { data: IPositions } = await baseApi.get('/search_what', {
        params: {mode: "modal"}
    });
    return data;
};

export const getSearchLocation = async (location: string): Promise<Locations> => {
    const {data}: { data: Locations } = await baseApi.get('/search_location', {
        params: {mode: "autocomplete", search: location}
    });
    return data;
};

export const addJobDemand = async (jobDemand: IJobDemand | Partial<IJobDemand>) => {
    const {data} = await baseApi.post('/job_demand', jobDemand);
    return data;
};

export const getCandidateByIdApi = async (candidateId: string): Promise<ICandidate> => {
    try {
        const params = {key: candidateId};
        const {data}: { data: ICandidate } = await baseApi.get<ICandidate>(`/candidate`, {params});
        return data;
    } catch (error) {
        console.error('Error fetching candidate:', error);
        throw error;
    }
};

export const getAllOutreachTemplates = async (): Promise<OutreachTemplate[]> => {
    try {
        const {data}: { data: OutreachTemplate[] } = await baseApi.get('/outreach_template');
        return data;
    } catch (error) {
        console.error('Error fetching outreach templates:', error);
        throw error;
    }
};

export const getJobDemand = async (params: { mode: string }): Promise<{ id: number, title: string }[]> => {
    const {data}: { data: { id: number, title: string }[] } = await baseApi.get('/job_demand', {params});
    return data;
};

export const getUserDetails = async (userId: number): Promise<User> => {
    const {data}: { data: User } = await authApi.get(`/users/${userId}`);
    return data;
};

export const addOutreachTemplate = async (template: OutreachTemplateRequest): Promise<OutreachTemplateResponse> => {
    try {
        const {data}: AxiosResponse<OutreachTemplateResponse> = await baseApi.post('/outreach_template', template);
        return data;
    } catch (error) {
        console.error('Error adding outreach template:', error);
        throw error;
    }
};

export const addOutreach = async (outreach: OutreachRequest): Promise<OutreachResponse> => {
    try {
        const {data}: AxiosResponse<OutreachResponse> = await baseApi.post('/outreaches', outreach);
        return data;
    } catch (error) {
        console.error('Error adding outreach:', error);
        throw error;
    }
};

export const updateOutreachTemplate = async (template: OutreachTemplateRequest): Promise<OutreachTemplateResponse> => {
    try {
        const {data}: AxiosResponse<OutreachTemplateResponse> = await baseApi.post(`/outreach_template/${template.id}`, template);
        return data;
    } catch (error) {
        console.error('Error updating outreach template:', error);
        throw error;
    }
};

export const deleteOutreachTemplate = async (id: number): Promise<void> => {
    try {
        await baseApi.delete(`/outreach_template/${id}`);
    } catch (error) {
        console.error('Error deleting outreach template:', error);
        throw error;
    }
};


export const getJobTypes = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/job_type');
    return data;
};

export const getHomeofficeOptions = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/homeoffice');
    return data;
};

export const getWorkloadOptions = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/work_load');
    return data;
};

export const getTargetGroupOptions = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/target_group');
    return data;
};

export const getJobs = async ({limit, offset}: { limit: number, offset: number }): Promise<IJobDetails[]> => {
    const {data}: AxiosResponse<IJobDetails[]> = await baseApi.get<IJobDetails[]>('/job_demand', {
        params: {
            mode: "job_ad",
            limit,
            offset
        }
    });
    return data;
};

export const getJobById = async (id: string): Promise<IJobDetails> => {
    const {data}: AxiosResponse<IJobDetails> = await baseApi.get<IJobDetails>(`/job_demand/${id}`);
    return data;
};

export const getJobByKey = async (key: string): Promise<IJobDetails[]> => {
    const params = {mode: "key", key: key}
    const {data}: AxiosResponse<IJobDetails[]> = await baseApi.get<IJobDetails[]>(`/job_demand`, {params});
    return data;
};

export const updateJobDemand = async (id: string, updatedData: Partial<IJobDetails>): Promise<IJobDetails> => {
    const {data}: { data: IJobDetails } = await baseApi.post<IJobDetails>(`/job_demand/${id}`, updatedData);
    return data;
};

export const deleteJobDemand = async (id: string): Promise<void> => {
    await baseApi.delete(`/job_demand/${id}`);
};

export const getClientProfile = async (clientId: number): Promise<IClientProfile> => {
    const {data}: { data: IClientProfile } = await baseApi.get<IClientProfile>(`/client/${clientId}`);
    return data;
};

export const updateClientProfile = async (clientId: number, updatedData: Partial<IClientProfile>): Promise<IClientProfile> => {
    const {data}: { data: IClientProfile } = await baseApi.post<IClientProfile>(`/client/${clientId}`, updatedData);
    return data;
};

export const getIndustrySectors = async (): Promise<IndustrySector[]> => {
    const {data}: { data: IndustrySector[] } = await baseApi.get<IndustrySector[]>('/industry_sector');
    return data;
};

export const getIndustrySubsectors = async (industrySectorId: number): Promise<IndustrySubsector[]> => {
    const {data}: { data: IndustrySubsector[] } = await baseApi.get<IndustrySubsector[]>('/industry_subsector', {
        params: {industry_sector_id: industrySectorId},
    });
    return data;
};

export const getMessages = async ({limit, offset}: { limit: number, offset: number }): Promise<IMessage[]> => {
    const {data}: AxiosResponse<IMessage[]> = await baseApi.get<IMessage[]>('/communication', {
        params: {
            limit,
            offset
        }
    });
    return data;
};

export const getMessageCount = async (): Promise<IMessage[]> => {
    const {data}: AxiosResponse<IMessage[]> = await baseApi.get<IMessage[]>('/communication', {
        params: {
            mode: 'count'
        }
    });
    return data;
};

export const getCandidateFromMsgByIdApi = async (communicationId: string): Promise<ICandidate> => {
    try {
        const params = {key: communicationId, mode: "key"};
        const {data}: { data: ICandidate } = await baseApi.get<ICandidate>(`/communication`, {params});
        return data;
    } catch (error) {
        console.error('Error fetching candidate:', error);
        throw error;
    }
};

export const getCandidateMessagesByComId = async (communication_id: number): Promise<IMessageBuble[]> => {
    try {
        const params = {communication_id};
        const {data}: { data: IMessageBuble[] } = await baseApi.get<IMessageBuble[]>(`/message`, {params});
        return data;
    } catch (error) {
        console.error('Error fetching candidate:', error);
        throw error;
    }
};

export const sendMessageApi = async (messageData: {
    message: string;
    type: string;
    client_id: number;
    candidate_id: number;
    job_demand_id: number;
    communication_id: number;
    contact_data: string;
}): Promise<IMessageBuble> => {
    try {
        const {data}: { data: IMessageBuble } = await baseApi.post<IMessageBuble>('/message', messageData);
        return data;
    } catch (error) {
        console.error('Error sending message:', error);
        throw error;
    }
};

export const getFinancePlanUrl = async (planKey: string): Promise<string> => {
    try {
        const authToken = getToken();
        const params = {
            id: authToken,
            product: planKey,
        };

        const response = await billingApi.get<{
            url: string
        }>('/checkout_session', {params});
        return response.request.responseURL
    } catch (error) {
        console.error('Error getting finance plan URL:', error);
        throw error;
    }
};

export const getMagicLink = async (email: string): Promise<{
    url: string
}> => {
    try {
        const params = {email};
        const {data}: { data: { url: string } } = await privacyApi.get<{
            url: string
        }>('/auth/request-magic-link', {params});
        return data;
    } catch (error) {
        console.error('Error changing plan:', error);
        throw error;
    }
};

export const getSearches = async (): Promise<ISearchResult> => {
    const params = {mode: "candidate_search"};
    const {data}: { data: ISearchResult } = await baseApi.get('/job_demand', {params});
    return data;
};

export const getSearchById = async (id: number): Promise<ISearchResult> => {
    const params = {mode: "candidate_search"};
    const {data}: { data: ISearchResult } = await baseApi.get(`/job_demand/${id}`, {params});
    return data;
};

export const updateSearchById = async (id: number, updatedData: Partial<IJobDetails>): Promise<IJobDetails> => {
    const {data}: { data: IJobDetails } = await baseApi.post<IJobDetails>(`/job_demand/${id}`, updatedData);
    return data;
};

export const getCompanySize = async (): Promise<CompanySizeType> => {
    const {data}: { data: CompanySizeType } = await baseApi.get<CompanySizeType>("/company_size");
    return data;
};

export const getAlertConfigs = async (): Promise<IAlertConfig> => {
    const {data}: { data: IAlertConfig } = await baseApi.get('/alert_config');
    return data;
};

export const getStripeUrl = async ({id, product, redirectPath}: { id: string, product: string, redirectPath: string }) => {
    const endpoint = import.meta.env.VITE_ENVIRONMENT === 'production'
        ? '/checkout_session_private'
        : '/checkout_session_private_test';

    const {data} = await privateBillingApi.post(`${endpoint}?id=${id}&product=${product}&redirectPath=${redirectPath}`);
    return data;
};
