import {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Card, Col, Input, List, Row, Typography} from 'antd';
import {formatDistanceToNow} from "date-fns";
import {de} from 'date-fns/locale'
import {getCandidateFromMsgByIdApi, getCandidateMessagesByComId, sendMessageApi} from "../../services/api.ts";
import {IMessage, IMessageBuble} from "../../services/types.ts";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import styled from "styled-components";
import {Loader} from "../../components/Loader.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {InfoRow, ListStyled} from "candidate/src/pages/messageDetail/MessageDetail.tsx";

const {Text} = Typography;
const {TextArea} = Input;

type FirstMessageType = Omit<IMessage, '_candidate_2_0' | '_client' | '_job_demand_with_labels'>;

type CombinedMessageType = IMessageBuble | FirstMessageType;
export const MessageDetail = () => {
    const [newMessage, setNewMessage] = useState("");
    const navigate = useNavigate();
    const {id = ""} = useParams<{ id: string }>();
    const messageListRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const queryClient = useQueryClient();


    const {data, isLoading, error}: UseQueryResult<IMessage[], Error> = useQuery({
        queryKey: ['candidate-det', id],
        queryFn: () => getCandidateFromMsgByIdApi(id),
        enabled: !!id,
    });

    const msgDetails = data?.[0];

    const {
        data: messages,
        isLoading: isMessagesLoading,
    }: UseQueryResult<IMessageBuble[], Error> = useQuery({
        queryKey: ['message', msgDetails?.id],
        queryFn: () => getCandidateMessagesByComId(msgDetails!.id),
        enabled: !!msgDetails?.id,
    });

    const sendMessageMutation = useMutation({
        mutationFn: sendMessageApi,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['message', msgDetails!.id]});
            setNewMessage("");
        }
    });

    const handleSendMessage = () => {
        if (!newMessage.trim() || !msgDetails) return;

        sendMessageMutation.mutate({
            message: newMessage,
            type: "outreach",
            client_id: msgDetails.client_id,
            candidate_id: msgDetails._candidate_2_0.id,
            job_demand_id: msgDetails._job_demand_with_labels[0]?.id || 0,
            communication_id: msgDetails.id,
            contact_data: msgDetails.contact_data,
        });
        setNewMessage("")
        scrollToBottom();
    };

    const scrollToBottom = useCallback(() => {
        if (messageListRef.current) {
            const firstChildDiv = messageListRef.current.querySelector('div');
            if (firstChildDiv) {
                firstChildDiv.scrollTop = firstChildDiv.scrollHeight;
            }
        }
    }, []);

    useLayoutEffect(() => {
        scrollToBottom();
    }, [messages, scrollToBottom]);

    if (isLoading) return <Loader/>;
    if (error) return <Alert message="Error" description={error.message} type="error"/>;
    if (!msgDetails) return <Alert message="No data available" type="warning"/>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {_candidate_2_0, _client, _job_demand_with_labels, ...firstMessage} = msgDetails
    const messageList: CombinedMessageType[] = messages ? [...messages, firstMessage].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()) : [];
    const candidateItems = [
        {
            label: 'Name',
            value: msgDetails.contact_data === "visible" ? `${_candidate_2_0.contact.first_name} ${_candidate_2_0.contact.last_name}` : undefined
        },
        {label: 'Email', value: msgDetails.contact_data === "visible" ? _candidate_2_0.contact.email : undefined},
        {label: 'Telefon', value: msgDetails.contact_data === "visible" ? _candidate_2_0.contact.phone : undefined},
        {
            label: 'Letzte Tätigkeit',
            value: `${_candidate_2_0.main_job.job_position}, ${_candidate_2_0.main_job.job_experience}`
        },
        {label: 'Wohnort', value: `${_candidate_2_0.location.postal_code} ${_candidate_2_0.display.city}`}
    ];

    const jobItems = [
        {label: 'Zielgruppe', value: _job_demand_with_labels[0]?.target_group_label},
        {label: 'Stellentyp', value: _job_demand_with_labels[0]?.job_type_label},
        {label: 'Arbeitspensum', value: _job_demand_with_labels[0]?.work_load_label},
        {label: 'Standort', value: _job_demand_with_labels[0]?.search_location_label},
        {label: 'Homeoffice', value: _job_demand_with_labels[0]?.homeoffice_label}
    ];

    const showJobItems = _job_demand_with_labels.length > 0 && _job_demand_with_labels[0]?.title !== "Keine Stelle angehängt oder die Stelle wurde gelöscht"

    return (
        <div>
            <PageTitle
                mainBtnText={"Zum Kandidatenprofil"}
                mainBtnType="default"
                onMainBtnClick={() => navigate(`/details/${msgDetails?.key}`)}
                title={msgDetails.type === "outreach" ? "Direktansprache eines Kandidaten" : "Bewerbung auf eine Stellenanzeige"}/>
            <Row gutter={24}>
                <Col span={isMobile ? 24 : 12}>
                    <Card title="Gesprächsverlauf">
                        <div ref={messageListRef}>
                            <ListStyled
                                style={{height: '300px', overflowY: 'auto'}}
                                loading={isMessagesLoading}
                                dataSource={messageList}
                                renderItem={(msg: CombinedMessageType) => (
                                    <List.Item>
                                        <Card style={{width: '100%', backgroundColor: 'white'}}>
                                            <MessageText>{msg.message}</MessageText>
                                            <MessageDate>{formatDistanceToNow(msg.created_at, {
                                                addSuffix: true,
                                                locale: de
                                            })}</MessageDate>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>
                        <MessageAreaBtnWrapper>
                            <TextArea rows={4} placeholder="Geben Sie hier Ihre Nachricht ein..."
                                      style={{marginBottom: '8px'}} value={newMessage}
                                      onChange={(e) => setNewMessage(e.target.value)}/>
                            <Button type="primary" onClick={handleSendMessage}
                                    loading={sendMessageMutation.isPending}>Nachricht senden</Button>
                        </MessageAreaBtnWrapper>
                        <div style={{marginTop: '24px'}}>
                            <Text>{msgDetails.contact_data === "visible" ? "Der Bewerber hat seine Kontaktdaten, soweit im Kandidatenprofil gepflegt, freigegeben." : "Der Bewerber hat seine Kontaktdaten leider nicht freigegeben."}</Text>
                        </div>
                    </Card>
                </Col>

                <Col span={isMobile ? 24 : 12}>
                    <Card
                        title={`Kandidat: ${_candidate_2_0?.key.slice(0,6) || "Dieser Kandidat hat seine Jobsuche abgeschlossen."}`}>
                        {!!msgDetails._candidate_2_0.id && (
                            candidateItems.filter(item=>!!item.value).map(item=>{
                                    return <InfoRow label={item.label} value={item.value}/>
                                })
                        )}
                    </Card>

                    {showJobItems && <Card
                        title={`Stelle: ${_job_demand_with_labels[0]?.title ?? "Keine Stelle angehängt oder die Stelle wurde gelöscht"}`}
                        style={{marginTop: '16px'}}>
                        {jobItems.filter(item => !!item.value).map(item => {
                            return <InfoRow label={item.label} value={item.value}/>
                        })}
                    </Card>}
                </Col>
            </Row>
        </div>
    );
};

export const EmptyDivFullWidth = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const MessageText = styled.p`
    margin: 0;
`;
const MessageDate = styled.p`
    margin: 0;
    font-weight: 300;
    font-size: 11px;
    color: #424242;
`;

const MessageAreaBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
`;
