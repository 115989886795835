import React, {useEffect} from 'react';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

let notificationApi: NotificationInstance;

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        notificationApi = api;
    }, [api]);

    return (
        <>
            {contextHolder}
            {children}
        </>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export const showNotification = (type: NotificationType, title: string, message: string) => {
    if (!notificationApi) {
        console.error('Notification API is not initialized. Make sure to wrap your app with NotificationProvider.');
        return;
    }

    notificationApi[type]({
        message: title,
        description: message,
    });
};

export default NotificationProvider;
