import {ReactNode} from 'react'
import {Button, theme, Typography} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {ButtonType} from "antd/es/button";

const {useToken} = theme;
const {Title} = Typography;

interface IPageTitle {
    children?: ReactNode
    onMainBtnClick?: () => void
    mainBtnText?: string
    title?: string
    description?: string
    mainBtnType?: ButtonType
    isLoading?: boolean
}

export const PageTitle = ({children, onMainBtnClick, mainBtnText, title, description, mainBtnType, isLoading}: IPageTitle) => {
    const navigate = useNavigate()
    const {token} = useToken();

    return <TitleWrapper>
        <BtnWrapper>
            <Button shape="circle" size="large" onClick={() => navigate(-1)} icon={<LeftOutlined/>}/>
            {title ? <div>
                    <Title style={{fontSize: token.fontSizeHeading3, marginBottom: 0}}>{title}</Title>
                    {description && <p style={{margin: 0, maxWidth: 600}}>{description}</p>}
                </div> :
                children}
        </BtnWrapper>
        {mainBtnText && <BtnStyled type={mainBtnType || "primary"} loading={isLoading} disabled={!!isLoading}
                                   onClick={onMainBtnClick} onTouchStart={onMainBtnClick}>{mainBtnText}</BtnStyled>}
    </TitleWrapper>
}

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

const BtnStyled = styled(Button)`
    width: 300px;
    border-radius: 30px;
    @media (max-width: 767px) {
        width: 100%;
    }
`;
