import {Link} from "react-router-dom";

export interface MenuItem {
    key: string;
    label: JSX.Element;
}

export const menuItems: MenuItem[] = [
    {
        key: "pool",
        label: <Link to="/">Stellenangebote</Link>,
    },
    {
        key: "messages",
        label: <Link to="/messages">Nachrichten</Link>,
    },
    {
        key: "profile",
        label: <Link to="/profile">Mein Profil</Link>,
    },
    {
        key: "account",
        label: <Link to="/account">Mein Konto</Link>,
    }
];
