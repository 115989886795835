import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {VariableSizeList as VirtualList} from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import {Spin} from 'antd';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {PageTitle} from "../../components/PageTitle.tsx";
import {useMyMessages} from "./hooks/useMyMessages.tsx";
import {ICandidateDetails, IMessage} from "../../services/types.ts";
import {MessageItem} from "./components/MessageItem.tsx";
import {getMessageCount} from "../../services/api.ts";
import {AbsLoader, TableWrapper} from "../jobPool/JobPool.tsx";
import {TableRow} from "../jobPool/TableRow.tsx";

const LIMIT = 50;
const CONTAINER_HEIGHT = window.innerHeight - 133;

export const Messages: FC = () => {
    const {data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage} = useMyMessages(LIMIT);
    const {data: messageCount, isLoading}: UseQueryResult<ICandidateDetails, Error> = useQuery({
        queryKey: ['messagesCount'],
        queryFn: () => getMessageCount(),
    });

    const listRef = useRef<VirtualList | null>(null);
    const sizeMap = useRef<{ [key: number]: number }>({});
    const [scrollPosition, setScrollPosition] = useState(0);
    const initialLoadRef = useRef(true);

    const flattenedData = useMemo(() => {
        if (!data || (data.pages.length === 1 && data.pages[0][0]?.id === 0)) {
            return [];
        }
        return data.pages.flatMap(page => Array.isArray(page) ? page : []);
    }, [data]);

    const itemCount = hasNextPage ? flattenedData.length + 1 : flattenedData.length;

    const loadMoreItems = useCallback(() => {
        if (!isFetchingNextPage) {
            return fetchNextPage().then(() => {
            });
        }
        return Promise.resolve();
    }, [isFetchingNextPage, fetchNextPage]);

    const isItemLoaded = useCallback((index: number) => {
        return !hasNextPage || index < flattenedData.length;
    }, [hasNextPage, flattenedData]);

    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = {...sizeMap.current, [index]: size};
        if (listRef.current) {
            listRef.current!.resetAfterIndex(index);
        }
    }, []);

    const getSize = (index: number) => sizeMap.current[index] || 145;

    const handleScroll = useCallback(({scrollOffset}: { scrollOffset: number }) => {
        if (!initialLoadRef.current) {
            setScrollPosition(scrollOffset);
            sessionStorage.setItem('messagesScrollPosition', scrollOffset.toString());
        }
    }, []);

    useEffect(() => {
        const savedScrollPosition = sessionStorage.getItem('messagesScrollPosition');
        if (savedScrollPosition) {
            const parsedPosition = parseInt(savedScrollPosition, 10);
            setScrollPosition(parsedPosition);
            setTimeout(() => {
                listRef.current?.scrollTo(parsedPosition);
                initialLoadRef.current = false;
            }, 0);
        } else {
            initialLoadRef.current = false;
        }
    }, []);

    return (
        <div>
            <PageTitle
                title="Nachrichten"
                description={`(${isLoading ? "0" : messageCount?.id} Gespräche)`}
            />
            {isFetching && <AbsLoader><Spin size="large"/></AbsLoader>}
            <TableWrapper>
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}
                >
                    {({onItemsRendered, ref}) => (
                        <VirtualList
                            height={CONTAINER_HEIGHT}
                            itemCount={itemCount}
                            itemSize={getSize}
                            onItemsRendered={onItemsRendered}
                            ref={(listInstance) => {
                                ref(listInstance);
                                listRef.current = listInstance;
                            }}
                            width="100%"
                            initialScrollOffset={scrollPosition}
                            onScroll={handleScroll}
                        >
                            {({index, style}) => (
                                <div style={style}>
                                    <TableRow
                                        index={index}
                                        setSize={setSize}
                                        flattenedData={flattenedData}
                                    >
                                        {() => <MessageItem
                                            key={flattenedData[index]?.id || index}
                                            item={flattenedData[index] as IMessage}
                                        />}
                                    </TableRow>
                                </div>
                            )}
                        </VirtualList>
                    )}
                </InfiniteLoader>
            </TableWrapper>
        </div>
    );
};
