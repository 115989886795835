import { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Space } from 'antd';

type PotJobType = {
    [key: string]: string | string[] | number[];
    languages: string;
    age: string;
    it: string;
    lastemployer_sector: string;
    self_description: string;
    languages_id: number[];
    language_german: string;
    language_english: string;
    language_other: string;
};

interface IMatchingPotentialJobsModal {
    isOpen: boolean;
    isLoading: boolean;
    potentialJobs: PotJobType;
    onClose: () => void;
    onFinish: (value: PotJobType) => void;
}

const jobsObjectArray:{id: string, label:string}[] = [
    { id: "potential_job_15", label: "Altenpflege" },
    { id: "potential_job_7", label: "Alltagshilfe (Babysitzen / Familienunterstützung)" },
    { id: "potential_job_6", label: "Alltagshilfe & Begleitung (Ältere / Kranke)" },
    { id: "potential_job_22", label: "Einfache Büro- & Verwaltungsarbeit" },
    { id: "potential_job_20", label: "Empfangsdienst (Rezeption / Pförtner / Garderobe)" },
    { id: "potential_job_12", label: "Fachwissen weitergeben" },
    { id: "potential_job_10", label: "Fahrdienst (Fahrrad)" },
    { id: "potential_job_9", label: "Fahrdienst (PKW / LKW)" },
    { id: "potential_job_23", label: "Flüchtlings- und Integrationshilfe" },
    { id: "potential_job_13", label: "Gartenarbeit" },
    { id: "potential_job_4", label: "Handwerkliches (Bau / Renovierung / Montage)" },
    { id: "potential_job_5", label: "Handwerkliches (Hausmeister)" },
    { id: "potential_job_21", label: "Kaufmännisches (Finance / Controlling / Buchhaltung / HR)" },
    { id: "potential_job_11", label: "Reinigung (Gebäude / Wohnungen)" },
    { id: "potential_job_3", label: "Sekretariat und Koordination" },
    { id: "potential_job_2", label: "Telefonieren (Kundenbetreuung)" },
    { id: "potential_job_1", label: "Telefonieren (Verkauf)" },
    { id: "potential_job_19", label: "Unterstützung Computer (Technikhilfe / IT Support)" },
    { id: "potential_job_16", label: "Unterstützung Einzelhandel (Kassierer / Verkäufer)" },
    { id: "potential_job_18", label: "Unterstützung Gastro (Bedienung / Theke)" },
    { id: "potential_job_8", label: "Unterstützung Kindergarten / Schule / Jugendzentrum" },
    { id: "potential_job_24", label: "Unterstützung Küche" },
    { id: "potential_job_17", label: "Unterstützung Lager- & Fertigungsarbeit" },
    { id: "potential_job_14", label: "Wachdienst" }
];


export const MatchingPotentialJobsModal: React.FC<IMatchingPotentialJobsModal> = ({
                                                                                      isOpen,
                                                                                      onClose,
                                                                                      potentialJobs,
                                                                                      onFinish,
                                                                                      isLoading
                                                                                  }) => {
    const [localPotentialJobs, setLocalPotentialJobs] = useState<PotJobType>(potentialJobs);

    useEffect(() => {
        setLocalPotentialJobs(potentialJobs);
    }, [potentialJobs]);

    const handleCheckboxChange = (jobId: string, checked: boolean) => {
        setLocalPotentialJobs(prev => ({
            ...prev,
            [jobId]: checked ? "true" : "false"
        }));
    };

    const handleSave = () => {
        onFinish(localPotentialJobs);
    };

    return (
        <Modal
            title="Ihre weiteren vorstellbaren Tätigkeiten"
            open={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Zurück
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleSave}>
                    Speichern
                </Button>,
            ]}
        >
            <Space direction="vertical" style={{width: '100%'}}>
                {jobsObjectArray.map((job) => (
                    <Checkbox
                        key={job.id}
                        checked={localPotentialJobs[job.id] === "true"}
                        onChange={(e) => handleCheckboxChange(job.id, e.target.checked)}
                    >
                        {job.label}
                    </Checkbox>
                ))}
            </Space>
        </Modal>
    );
};
