import {useContext, useState} from 'react'
import {PageTitle} from "../../../components/PageTitle.tsx";
import {Alert, Card, Space, Typography} from "antd";
import {AuthContext, User} from "../../../context/AuthContext.tsx";
import {LocationsModal} from "./components/LocationsModal.tsx";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateCandidate} from "../../../services/api.ts";
import {RadiusModal} from "./components/RadiusModal.tsx";
import {DeepPartialCandidateProfile} from "../../../services/types.ts";
import {SectionTitle} from "./WorkHistory.tsx";
import {InfoRowWrapper} from "./Education.tsx";
import {AlertWrapper} from "../../deleteProfile/DeleteProfile.tsx";

const {Text} = Typography;


const placeholderValues: Record<string, string> = {
    "Ansprache": "keine Angabe, bitte ergänzen Sie die Ansprache",
    "Vorname": "keine Angabe, bitte ergänzen Sie Ihren Vornamen",
    "Nachname": "keine Angabe, bitte ergänzen Sie Ihren Nachnamen",
    "Telefonnummer": "keine Angabe, bitte ergänzen Sie Ihre Telefonnummer",
    "Email": "keine Angabe, bitte ergänzen Sie Ihre Email",

    "Arbeitspensum": "keine Angabe, bitte ergänzen Sie Ihr mögliches Arbeitspensum",
    "Ehrenamtliche Tätigkeiten": "keine Angabe, bitte ergänzen Sie, ob Sie offen für ehrenamtliche Tätigkeiten sind",
    "Sprachen": "keine Angabe, bitte ergänzen Sie Ihre Sprachen",
    "Reine Homeofficestellen": "Nein"
};


const InfoRow = ({label, value}: { label: string; value?: string }) => (
    <InfoRowWrapper>
        <Text strong>{label}:</Text>
        <Text>{value || placeholderValues[label]}</Text>
    </InfoRowWrapper>
);

export const Location = () => {
    const [isLocationsModalVisible, setLocationsModalVisibility] = useState(false)
    const [isRadiusModalVisible, setRadiusModalVisibility] = useState(false)
    const {user, setUser} = useContext(AuthContext);
    const queryClient = useQueryClient();

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({...user, _candidate: {...user!._candidate, ...data}} as User);
            queryClient.invalidateQueries({queryKey: ['candidate']});
        },
    });

    return <Space direction="vertical" size="large" className="w-full">
        <PageTitle title="Suchort"/>
        <Card title={<SectionTitle title="Ihr Wohnort"
                                   onEdit={() => setLocationsModalVisibility(true)}/>}>
            <Space direction="vertical" className="w-full">
                <InfoRow label="Ihr Suchradius"
                         value={user?._candidate!.location.postal_code + " " + user?._candidate!.display.city}/>
                <InfoRow label="Straße"
                         value={user?._candidate?.location.street}/>
            </Space>
            <AlertWrapper>
                <Alert
                    message={`Bitte geben Sie Ihre komplette Anschrift an, damit wir die Entfernung des Arbeitsorts zu Ihrem Wohnort besser berechnen können.`}
                    type="info"
                    showIcon
                    style={{marginTop: 8, marginBottom: 8}}
                />
            </AlertWrapper>
        </Card>

        <Card title={<SectionTitle title="Ihr Suchradius" onEdit={() => setRadiusModalVisibility(true)}/>}>
            <Space direction="vertical" className="w-full">
                <InfoRow label="Suchradius: " value={user!._candidate!.location.radius / 1000 + "km"}/>
            </Space>
            <AlertWrapper>
                <Alert
                    message={`Je weiter Ihr Suchradius, desto mehr Stellen bekommen Sie vorgeschlagen und desto sichtbarer sind Sie für Arbeitgeber.`}
                    type="info"
                    showIcon
                    style={{marginTop: 8, marginBottom: 8}}
                />
            </AlertWrapper>
        </Card>
        {isLocationsModalVisible &&
            <LocationsModal isLoading={updateMutation.isPending}
                            initialValues={user!._candidate!.location}
                            visible={isLocationsModalVisible}
                            onCancel={() => setLocationsModalVisibility(false)}
                            onFinish={
                                async (values) => {
                                    await updateMutation.mutateAsync({
                                        id: user!._candidate!.id,
                                        body: {
                                            id: user!._candidate!.id,
                                            location: values,
                                            ops: {mode: "search_location_update"},
                                        } as DeepPartialCandidateProfile
                                    })
                                    setLocationsModalVisibility(false);
                                }
                            }
            />}
        {isRadiusModalVisible &&
            <RadiusModal isLoading={updateMutation.isPending}
                         initialValues={user!._candidate!.location}
                         visible={isRadiusModalVisible}
                         onCancel={() => setRadiusModalVisibility(false)}
                         onFinish={
                             async (values) => {
                                 await updateMutation.mutateAsync({
                                     id: user!._candidate!.id,
                                     body: {
                                         id: user!._candidate!.id,
                                         location: values,
                                         ops: {mode: "search_radius_update"},
                                     } as DeepPartialCandidateProfile
                                 })
                                 setRadiusModalVisibility(false);
                             }
                         }
            />}
    </Space>
}
