import {EditModal, ValueType} from "./EditModal.tsx";
import {EditOutlined} from "@ant-design/icons";
import {Col, Row, Typography} from "antd";
import {FC, useState} from "react";


const {Text} = Typography;

interface DetailItemProps {
    title: string;
    content: string;
    editable?: boolean;
    onEdit?: () => void;
    inputType?: 'input' | 'select' | 'double-select';
    options?: { value: string; label: string }[];
    secondOptions?: { value: string; label: string }[]
    onSave?: (value: ValueType) => void;
}

export const DetailItem: FC<DetailItemProps> = ({
                                                    title,
                                                    content,
                                                    editable,
                                                    onEdit,
                                                    inputType,
                                                    options,
                                                    secondOptions,
                                                    onSave
                                                }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEdit = () => {
        if (onEdit) {
            onEdit();
        } else {
            setIsModalOpen(true);
        }
    };

    const handleSave = (value: ValueType) => {
        if (onSave) {
            onSave(value);
        }
        setIsModalOpen(false);
    };

    return (
        <Row justify="space-between" align="middle" style={{padding: '8px 0'}}>
            <Col>
                <Text strong>{title}</Text>
                <br/>
                <Text>{content}</Text>
            </Col>
            {editable && (
                <Col>
                    <EditOutlined onClick={handleEdit} style={{cursor: 'pointer'}}/>
                </Col>
            )}
            {inputType && (
                <EditModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSave}
                    title={modalTitleMap[title] ?? title}
                    initialValue={content ?? ''}
                    inputType={inputType}
                    options={options}
                    secondOptions={secondOptions}
                />
            )}
        </Row>
    );
};

const modalTitleMap: Record<string, string> = {
    "Meine Suche": "Titel der Jobsuche bearbeiten",
    "Einstellungen": "Einstellungen der Jobsuche bearbeiten",
};
