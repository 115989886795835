import {ReactNode, useContext, useState} from 'react';
import {Alert, Button, List, Modal, Space, Typography} from 'antd';
import {EnvironmentOutlined, SolutionOutlined, StarOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons';
import {PageTitle} from "../../components/PageTitle.tsx";
import {AuthContext, User} from "../../context/AuthContext.tsx";
import {Link} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {addNpsRecord, modifyCandDeactivateSurvey, updateCandidate} from "../../services/api.ts";
import {SurveyModal} from "./components/SurveyModal.tsx";
import {DeepPartialCandidateProfile} from "../../services/types.ts";
import {ScoreModal} from "./components/ScoreModal.tsx";
import {AlertWrapper} from "../deleteProfile/DeleteProfile.tsx";

const {Text} = Typography;

const menuItems = [
    {
        icon: <SolutionOutlined/>,
        title: "Letzte Arbeitgeber",
        description: "Ihre letzten Arbeitsstationen und Tätigkeiten",
        path: "/work-history"
    },
    {
        icon: <StarOutlined/>,
        title: "Berufsausbildung",
        description: "Ihre berufsrelevante Ausbildung und Weiterbildungen",
        path: "/education"
    },
    {
        icon: <UserOutlined/>,
        title: "Persönliches",
        description: "Ihr Namen und Kontaktdaten sowie persönliche Vorstellungen",
        path: "/personal"
    },
    {
        icon: <TeamOutlined/>,
        title: "Gesuchte Tätigkeiten",
        description: "Ihre vorstellbaren Tätigkeiten und Arbeitsbereiche",
        path: "/desired-jobs"
    },
    {
        icon: <EnvironmentOutlined/>,
        title: "Suchort",
        description: "Ihr Wohnort und Suchradius",
        path: "/location"
    }
];

interface ProfileItemProps {
    icon: ReactNode;
    title: string;
    description: string;
    path: string;
}

const ProfileItem = ({icon, title, description, path}: ProfileItemProps) => (
    <List.Item>
        <Link to={path} style={{display: 'block', width: '100%'}}>
            <List.Item.Meta
                avatar={icon}
                title={<Text strong>{title}</Text>}
                description={description}
            />
        </Link>
    </List.Item>
);

export const MyProfile = () => {
    const {user, setUser} = useContext(AuthContext);
    const queryClient = useQueryClient();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [surveyModalVisible, setSurveyModalVisible] = useState(false);
    const [npsModalVisible, setNpsModalVisible] = useState(false);
    const [surveyResponse, setSurveyResponse] = useState<number | null>(null);
    const [npsScore, setNpsScore] = useState<number>(-1);

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({...user, _candidate: {...user!._candidate, ...data}} as User);
            queryClient.invalidateQueries({queryKey: ['candidate']});
        },
    });

    const updateDeactivateSurveyMutation = useMutation({
        mutationFn: modifyCandDeactivateSurvey,
        onSuccess: () => {
            setSurveyModalVisible(false);
            setNpsModalVisible(true);
        },
    });

    const addNpsRecordMutation = useMutation({
        mutationFn: addNpsRecord,
        onSuccess: () => {
            setNpsModalVisible(false);
        },
    });

    const handleDeactivateSearch = () => {
        setConfirmModalVisible(true);
    };

    const handleActivateSearch = async () => {
        await updateMutation.mutateAsync({
            id: user!._candidate!.id,
            body: {
                id: user!._candidate!.id,
                ops: {mode: "job_search_change", status: 'active_visible'}
            } as DeepPartialCandidateProfile
        });
    };

    const handleConfirmDeactivate = async () => {
        await updateMutation.mutateAsync({
            id: user!._candidate!.id,
            body: {
                id: user!._candidate!.id,
                ops: {mode: "job_search_change", status: 'inactive'}
            } as DeepPartialCandidateProfile
        });
        setConfirmModalVisible(false);
        setSurveyModalVisible(true);
    };

    const handleSurveySubmit = async () => {
        if (surveyResponse !== null) {
            await updateDeactivateSurveyMutation.mutateAsync({
                users_id: user!.id,
                candidate_id: user!._candidate!.id,
                response: surveyResponse
            });
        }
    };

    const handleNpsSubmit = async () => {
        if (npsScore !== null) {
            await addNpsRecordMutation.mutateAsync({
                users_id: user!.id,
                candidate_id: user!._candidate!.id,
                points: npsScore
            });
        }
    };

    const handleNpsButtonClick = (score: number) => {
        setNpsScore(score);
    };

    return (
        <div className="p-4">
            <PageTitle title="Profil"/>
            <AlertWrapper>
                {user?._candidate?.ops.status !== 'inactive' ? (
                    <Alert
                        showIcon
                        message="Ihre Jobsuche ist aktiv."
                        description="Sie erhalten Jobvorschläge und Ihr anonymes Kurzprofil wird Arbeitgebern im silvertalent Kandidatenpool angezeigt."
                        type="info"
                        action={
                            <Space direction="vertical">
                                <Button type="primary" onClick={handleDeactivateSearch}>
                                    Suche Deaktivieren
                                </Button>
                            </Space>
                        }
                    />
                ) : (
                    <Alert
                        message="Ihr Jobsuche ist inaktiv."
                        description="Sie erhalten keine Jobvorschläge und Ihr anonymes Kurzprofil wird Arbeitgebern aktuell nicht angezeigt."
                        type="info"
                        action={
                            <Space direction="vertical">
                                <Button type="primary" onClick={handleActivateSearch}>
                                    Suche Aktivieren
                                </Button>
                            </Space>
                        }
                    />
                )}
            </AlertWrapper>
            <List itemLayout="horizontal">
                {menuItems.map((item, index) => (
                    <ProfileItem
                        key={index}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                        path={item.path}
                    />
                ))}
            </List>

            <Modal
                title="Wollen Sie Ihre Jobsuche wirklich deaktivieren?"
                open={confirmModalVisible}
                onOk={handleConfirmDeactivate}
                onCancel={() => setConfirmModalVisible(false)}
                okText="Suche deaktivieren"
                cancelText="Zurück"
            >
                <p>Hinweis: Wenn Sie Ihren Suchstatus auf inaktiv setzen, bekommen Sie keine Jobvorschläge für Ihre
                    Suchen mehr zugeschickt und Arbeitgeber können Ihr anonymes Kandidatenprofil nicht mehr sehen und
                    Sie nicht mehr kontaktieren.</p>
            </Modal>

            <SurveyModal
                visible={surveyModalVisible}
                onOk={handleSurveySubmit}
                onCancel={() => setSurveyModalVisible(false)}
                surveyResponse={surveyResponse}
                setSurveyResponse={setSurveyResponse}
            />
            <ScoreModal npsModalVisible={npsModalVisible} npsScore={npsScore}
                        handleNpsButtonClick={handleNpsButtonClick} handleNpsSubmit={handleNpsSubmit}
                        setNpsModalVisible={setNpsModalVisible}/>
        </div>
    );
};
