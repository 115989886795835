import {Button, Modal} from "antd";
import styled from "styled-components";

interface IScoreModal {
    npsModalVisible: boolean,
    handleNpsSubmit: () => void,
    setNpsModalVisible: (value: boolean) => void,
    handleNpsButtonClick: (value: number) => void,
    npsScore: number
}

export const ScoreModal = ({
                               npsModalVisible,
                               handleNpsSubmit,
                               setNpsModalVisible,
                               handleNpsButtonClick,
                               npsScore
                           }: IScoreModal) => {
    return <Modal
        title="Wir freuen uns auf Ihr Feedback"
        open={npsModalVisible}
        onOk={handleNpsSubmit}
        onCancel={() => setNpsModalVisible(false)}
        okText="Speichern"
        width={600}
        cancelText="Abbrechen"
    >
        <h3>Wie wahrscheinlich ist es, dass Sie silvertalent einem Bekannten weiterempfehlen?</h3>
        <ScoreWrapper>
            {[...Array(11)].map((_, i) => (
                <Button
                    key={i}
                    onClick={() => handleNpsButtonClick(i)}
                    type={npsScore === i ? 'primary' : 'default'}
                >
                    {i}
                </Button>))}
        </ScoreWrapper>
        <p>Sehr unwahrscheinlich Sehr wahrscheinlich</p>
    </Modal>
}

const ScoreWrapper = styled.div`
    display: flex;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
`;
