import {FC, ReactNode, useEffect, useState} from 'react';
import {Button, Drawer, Layout, Menu, theme} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import logo from "../assets/logo.png";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {colors} from "../util/colors.ts";
import {MenuItem} from "./menuItems.tsx";
import {User} from "../context/AuthContext.tsx";
import {User as ClientUser} from "client/src/context/AuthContext.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

const {Header, Content} = Layout;

interface LayoutWrapperProps {
    children: ReactNode;
    menuItems: MenuItem[];
    signOut: () => void;
    isClient?: boolean;
    user?: User | ClientUser
}

export const LayoutWrapper: FC<LayoutWrapperProps> = ({children, user, menuItems, signOut, isClient}) => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const location = useLocation();
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const navigate = useNavigate()
    const isPrivateUser = (user as ClientUser)?._client?.isPrivate;

    const canPostJob = isClient && user?.jobs_current_month === 0
    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    useEffect(() => {
        setMobileMenuVisible(false)
    }, [location])

    const menuContent = (
        <Menu
            theme="light"
            mode="vertical"
            selectedKeys={[location.pathname.slice(1) || 'pool']}
            items={menuItems}
        />
    );

    return (
        <Layout>
            <StyledHeader style={{backgroundColor: colorBgContainer}}>
                <Wrapper>
                    <LogoContainer>
                        <Link to={"/"}>
                            <Logo src={logo as string} alt="Silvertalent logo"/>
                        </Link>
                    </LogoContainer>
                    <MenuContainer>
                        <DesktopMenu
                            overflowedIndicator={false}
                            theme="light"
                            mode="horizontal"
                            selectedKeys={[location.pathname.slice(1) || 'pool']}
                            items={menuItems}
                        />
                        <MobileMenuButton
                            icon={<MenuOutlined/>}
                            onTouchStart={toggleMobileMenu}
                            onClick={toggleMobileMenu}
                        />
                    </MenuContainer>
                </Wrapper>
                {!isMobile && isClient && canPostJob &&
                    !location.pathname.match(/^\/(?:jobs|new\/(?:job|profile))$/) && (
                        <Button
                            onClick={() => navigate(
                                (!isPrivateUser && !(user as ClientUser)?._client?.profile_name)
                                    ? "/new/profile"
                                    : "/new/job"
                            )}
                            style={{backgroundColor: colors.darkGreen, color: 'white', border: "none"}}
                        >
                            Anzeige schalten
                        </Button>
                    )
                }
                <DesktopSignOutButton onClick={signOut}>Abmelden</DesktopSignOutButton>
            </StyledHeader>
            <Drawer
                title=""
                placement="right"
                onClose={toggleMobileMenu}
                open={mobileMenuVisible}
                bodyStyle={{padding: 0}}
            >
                {menuContent}
                <div style={{padding: 16}}>
                    <Button onClick={signOut} onTouchStart={signOut} block>Abmelden</Button>
                </div>
            </Drawer>
            <StyledContent>
                {children}
            </StyledContent>
        </Layout>
    );
};


const StyledHeader = styled(Header)`
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    border-bottom: 1px solid #d9d9d9;
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 60px;
    }
    @media (max-width: 767px) {
        padding: 0 8px;
        margin-bottom: 8px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.img`
    cursor: pointer;
    width: 150px;
    margin-right: 16px;
`;

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .appHeader_menuContainer {
        min-width: 100%;
    }

    .ant-menu-horizontal {
        flex: 1;
        width: 100%;
    }

    @media (max-width: 767px) {
        width: initial;
    }
`;

const DesktopMenu = styled(Menu)`
    @media (max-width: 767px) {
        display: none !important;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 10px;
        li {
            padding: 0 8px !important;
        }
    }
    
    border: none;
`;

const MobileMenuButton = styled(Button)`
    margin-left: 16px;
    @media (min-width: 768px) {
        display: none !important;
    }
`;

const StyledContent = styled(Content)`
    background-color: ${colors.bg};
    padding: 8px 100px 0;
    @media (max-width: 767px) {
        padding: 0 8px;
    }
`;

const DesktopSignOutButton = styled(Button)`
    margin-left: 16px;
    color: black;
    border-color: black;
    @media (max-width: 767px) {
        display: none !important;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    @media (max-width: 767px) {
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
`;
