import {List, Typography} from 'antd';
import {FileTextOutlined, SearchOutlined, UserDeleteOutlined, UserOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {PageTitle} from "../../components/PageTitle.tsx";

const {Text} = Typography;

const menuItems = [
    {
        icon: <FileTextOutlined/>,
        title: "Vorlagen",
        description: "Managen Sie Ihre Vorlagen für die Kandidatenansprache",
        path: "/templates"
    },
    {
        icon: <UserOutlined/>,
        title: "Zugangsdaten",
        description: "Ändern Sie Ihr Passwort oder Ihre Emailadresse",
        path: "/privacy"
    },
    {
        icon: <SearchOutlined/>,
        title: "Meine Suchen",
        description: "Managen Sie Ihre gespeicherten Suchen",
        path: "/searches"
    },
    {
        icon: <UserDeleteOutlined/>,
        title: "Konto löschen",
        description: "Löschen Sie Ihr silvertalent Konto",
        path: "/delete-profile"
    }
];


export const Account = () => {
    return (
        <div>
            <PageTitle title="Mein Konto"/>

            <List
                itemLayout="horizontal"
                dataSource={menuItems}
                renderItem={(item) => (
                    <List.Item>
                        <Link to={item.path} style={{display: 'block', width: '100%'}}>
                            <List.Item.Meta
                                avatar={item.icon}
                                title={<Text strong>{item.title}</Text>}
                                description={item.description}
                            />
                        </Link>
                    </List.Item>
                )}
            />
        </div>
    );
};
