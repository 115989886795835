import {useContext, useState} from 'react';
import {Alert, Button, Card, Checkbox, List, Space, Spin, Typography} from 'antd';
import {PageTitle} from "../../../components/PageTitle";
import {AuthContext, User} from "../../../context/AuthContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getCandidateMatchingPositions, getCandidateMatchingPotJobs, updateCandidate} from "../../../services/api";
import {MatchingPotentialJobsModal} from "./components/MatchingPotentialJobsModal.tsx";
import {useNavigate} from "react-router-dom";
import {ExcItem, ExcludePositionModal} from "./components/ExcludePositionModal.tsx";
import {DeepPartialCandidateProfile, DesJobType, MatchingPositionsType} from "../../../services/types.ts";
import styled from "styled-components";
import {useMediaQuery} from "@uidotdev/usehooks";
import {SectionTitle} from "./WorkHistory.tsx";
import {AlertWrapper} from "../../deleteProfile/DeleteProfile.tsx";

const {Text} = Typography;

export const DesiredJobs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExcludeOpen, setExcludeModal] = useState(false);
    const [excludingItem, setExcludingItem] = useState<ExcItem | null>(null);

    const {user, setUser} = useContext(AuthContext);
    const queryClient = useQueryClient();
    const navigate = useNavigate()
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({...user, _candidate: {...user!._candidate, ...data}} as User);
            queryClient.invalidateQueries({queryKey: ['candidate']});
            queryClient.invalidateQueries({queryKey: ['matchingPotJobs']});
        },
    });
    const {data: matchingPositions} = useQuery<MatchingPositionsType[]>({
        queryKey: ['matchingPositions'],
        queryFn: getCandidateMatchingPositions
    });
    const {data: matchingPotJobs, isFetching} = useQuery<DesJobType[]>({
        queryKey: ['matchingPotJobs'],
        queryFn: getCandidateMatchingPotJobs
    });

    const openModal = () => setIsModalOpen(true);
    const showExcludeModal = (item: ExcItem) => {
        setExcludingItem(item)
        setExcludeModal(true);
    }
    const closeModal = () => setIsModalOpen(false);
    const hideExcludeModal = () => setExcludeModal(false);

    if (isFetching) return <Spin/>

    return (
        <Space direction="vertical" size="large" className="w-full">
            <PageTitle title="Gesuchte Tätigkeiten"/>

            <Card style={{width: isMobile ? "calc(100vw - 32px)" : '100%'}}
                  title={<SectionTitle
                      title="Tätigkeiten mit Berufserfahrung (aus Ihren Angaben in Berufsausbildung und Arbeitgeber)"/>}>
                <List
                    locale={{emptyText: "Keine Daten"}}
                    dataSource={matchingPositions}
                    renderItem={(item: ExcItem) => (
                        <List.Item>
                            <Space direction="vertical" className="w-full">
                                <Space className="w-full justify-between" align="center">
                                    <Text strong>{item.label}</Text>
                                    <Checkbox checked={item.matching_used === "true"}
                                              onClick={() => showExcludeModal(item)}/>
                                </Space>
                                <BtnWrapper>
                                    <Button
                                        onClick={() => {
                                            navigate("/work-history")
                                        }}
                                        disabled={item.match_type_employer !== "true"}
                                        type={item.match_type_employer === "true" ? "default" : "primary"}
                                    >
                                        Arbeitgeber
                                    </Button>
                                    <Button
                                        disabled={item.match_type_education !== "true"}
                                        type={item.match_type_education === "true" ? "default" : "primary"}
                                        onClick={() => {
                                            navigate("/education")
                                        }}
                                    >
                                        Berufsausbildung
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            navigate("/education")
                                        }}
                                        disabled={item.match_type_workexperience !== "true"}
                                        type={item.match_type_workexperience === "true" ? "default" : "primary"}
                                    >
                                        Weitere Berufserfahrung
                                    </Button>
                                </BtnWrapper>
                            </Space>
                        </List.Item>
                    )}
                />
                {user?._candidate?.preferences.work_where_candidate_id === 4 && <AlertWrapper>
                    <Alert
                        message={`Da Sie im Registrierungsprozess angegeben haben, dass Sie in einer anderen Tätigkeit als Ihren Früheren arbeiten möchten, wird Ihre Berufserfahrung nicht zum Matching verwendet. Sie können dies hier ändern.`}
                        type="info"
                        showIcon
                        style={{marginTop: 8, marginBottom: 8}}
                    />
                </AlertWrapper>}

            </Card>
            <Card title={<SectionTitle title="Weitere vorstellbaren Tätigkeiten" onEdit={openModal}/>}>
                <List
                    dataSource={matchingPotJobs as DesJobType[]}
                    renderItem={(item: DesJobType) => (
                        <List.Item>
                            <Space className="w-full justify-between">
                                <Text strong>{item.job_2_0 || item.job}</Text>
                            </Space>
                        </List.Item>
                    )}
                />
            </Card>
            {isModalOpen && <MatchingPotentialJobsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                potentialJobs={user!._candidate!.additional}
                isLoading={updateMutation.isPending}
                onFinish={async (values) => {
                    await updateMutation.mutateAsync({
                        id: user!._candidate!.id,
                        body: {
                            id: user!._candidate!.id,
                            additional: values,
                            ops: {mode: "potential_jobs_update"},
                        } as DeepPartialCandidateProfile
                    })
                    closeModal();
                }}
            />}
            {isExcludeOpen && !!excludingItem && <ExcludePositionModal
                isOpen={isExcludeOpen}
                excludingItem={excludingItem}
                onClose={hideExcludeModal}
            />}
        </Space>
    );
};

const BtnWrapper = styled.div`
    display: flex;
    gap: 8px;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`
