import {useContext, useState} from 'react';
import {Alert, Card, Space} from 'antd';
import {AuthContext, User} from "../../../context/AuthContext.tsx";
import {PageTitle} from "../../../components/PageTitle.tsx";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateCandidate} from "../../../services/api.ts";
import {DegreesModal} from "./components/DegreesModal.tsx";
import {TrainingsModal} from "./components/TrainingsModal.tsx";
import {DeepPartialCandidateProfile} from "../../../services/types.ts";
import {SectionTitle} from "./WorkHistory.tsx";
import styled from "styled-components";
import {AlertWrapper} from "../../deleteProfile/DeleteProfile.tsx";

const placeholderValues: Record<string, string> = {
    "Höchster Abschluss": "keine Angabe, bitte ergänzen Sie Ihren höchsten Abschluss",
    "Titel des höchsten Abschlusses": "keine Angabe, bitte ergänzen Sie Ihren Abschluss",
    "Weitere Abschlüsse": "keine Angabe",

    "Berufsrelevante Weiterbildungen": "keine Angabe",
    "Spezialkentnisse in Software oder Geräten": "keine Angaben",
};

export const Education: React.FC = () => {
    const {user, setUser} = useContext(AuthContext);
    const [degreesModalVisible, setDegreesModalVisible] = useState(false);
    const [trainingsModalVisible, setTrainingsModalVisibleVisible] = useState(false);
    const queryClient = useQueryClient();

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({...user, _candidate: {...user!._candidate, ...data}} as User);
            queryClient.invalidateQueries({queryKey: ['candidate']});
        },
    });

    const InfoRow = ({label, value}: { label: string; value?: string }) => (
        <InfoRowWrapper>
            <p><b>{label}:</b></p>
            <p>{value || placeholderValues[label]}</p>
        </InfoRowWrapper>
    );

    return (
        <Space direction="vertical" size="large" className="w-full">
            <PageTitle title="Ihre Berufsausbildung"/>

            <Card title={<SectionTitle title="Abschlüsse"
                                       onEdit={() => setDegreesModalVisible(true)}/>}>
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Höchster Abschluss" value={user?._candidate?.education.level}/>
                    <InfoRow label="Titel des höchsten Abschlusses"
                             value={user?._candidate?.education.degree}/>
                    <InfoRow label="Weitere Abschlüsse"
                             value={user?._candidate?.education.more_title}/>
                </Space>
            </Card>

            <Card title={<SectionTitle title="Weiterbildungen und Spezialkentnisse"
                                       onEdit={() => setTrainingsModalVisibleVisible(true)}/>}>
                <Space direction="vertical" className="w-full">
                    <InfoRow label="Berufsrelevante Weiterbildungen" value={user?._candidate?.education.trainings}/>
                    <InfoRow label="Spezialkentnisse in Software oder Geräten"
                             value={user?._candidate?.education.software}/>
                </Space>
            </Card>
            {!!user?._candidate?.display.work_experience && <AlertWrapper>
                <Alert
                    message={`Sie haben im Registrierungsprozess angegeben, dass Sie in weiteren Bereichen eine anerkannte Berufsausbildung haben (${user!._candidate?.display.work_experience}). Sobald Sie hier unter Abschlüsse genauere Angaben machen, werden nur diese für das Matching benutzt.`}
                    type="info"
                    showIcon
                    style={{marginTop: 8, marginBottom: 8}}
                />
            </AlertWrapper>}
            {degreesModalVisible && (
                <DegreesModal
                    visible={degreesModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setDegreesModalVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                education: values,
                                ops: {mode: "education_degree_update"},
                            } as DeepPartialCandidateProfile
                        })
                        setDegreesModalVisible(false);
                    }}
                    initialValues={user!._candidate!.education}
                />
            )}
            {trainingsModalVisible && (
                <TrainingsModal
                    visible={trainingsModalVisible}
                    isLoading={updateMutation.isPending}
                    onCancel={() => setTrainingsModalVisibleVisible(false)}
                    onFinish={async (values) => {
                        await updateMutation.mutateAsync({
                            id: user!._candidate!.id,
                            body: {
                                id: user!._candidate!.id,
                                education: values,
                                ops: {mode: "trainings_software_update"},
                            } as DeepPartialCandidateProfile
                        })
                        setTrainingsModalVisibleVisible(false);
                    }}
                    initialValues={user!._candidate!.education}
                />
            )}
        </Space>
    );
};

export const InfoRowWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
    p{
        width: fit-content;
        margin: 0;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        gap: 0px;
    }
`
