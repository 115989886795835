import {useEffect} from 'react';
import {Button, Form, Input, Modal, Select} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getExperienceYears, getSectors, getSubsectors} from "../../../../services/api.ts";
import {JobExpType, SectorType} from "../../../../services/types.ts";
import {useMediaQuery} from "@uidotdev/usehooks";

export type initialValuesType = {
    subsector: string
    job_position: string
    job_experience: string
    job_level: string
    sector: string
    job_position_group: string
    position_group_id: number
    positions_id: Array<number>
    specialization: string
    experience_years_id: number
    sector_id: number
    subsector_id: number
}

export type formValuesType = {
    values: {
        sector_id: number
        subsector_id: number
        job_position: string
        experience_years_id: number
    }
}

interface MainJobModalProps {
    visible: boolean;
    onCancel: () => void;
    onFinish: (values: formValuesType) => void;
    initialValues: initialValuesType;
    selectedSector: string | null;
    setSelectedSector: (value: string) => void;
    isLoading: boolean;
}


export const MainJobModal: React.FC<MainJobModalProps> = ({
                                                              visible,
                                                              onCancel,
                                                              onFinish,
                                                              initialValues,
                                                              selectedSector,
                                                              setSelectedSector,
                                                              isLoading
                                                          }) => {
    const {data: sectors} = useQuery({queryKey: ['sectors'], queryFn: getSectors});
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const {data: subsectors} = useQuery({
        queryKey: ['subsectors', selectedSector],
        queryFn: () => getSubsectors(selectedSector!),
        enabled: !!selectedSector,
    });
    const {data: experienceYears} = useQuery({queryKey: ['experienceYears'], queryFn: getExperienceYears});

    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues && sectors) {
            const sectorId = sectors.find(s => s.id === initialValues.sector_id)?.id;
            if (sectorId) {
                setSelectedSector(sectorId.toString());
                form.setFieldsValue({
                    sector_id: sectorId,
                    subsector_id: initialValues.subsector_id,
                    job_position: initialValues.job_position,
                    experience_years_id: initialValues.experience_years_id
                });
            }
        }
    }, [initialValues, sectors, form, setSelectedSector]);

    const handleSectorChange = (value: string) => {
        setSelectedSector(value);
        form.setFieldsValue({subsector_id: undefined});
    };

    const handleOk = () => {
        form.submit();
    };

    const sectorOptions = sectors?.map((sector: SectorType) => ({
        value: sector.id,
        label: sector.name
    }));

    const subsectorOptions = subsectors?.map((subsector: SectorType) => ({
        value: subsector.id,
        label: subsector.name
    }));

    const experienceYearOptions = experienceYears?.map((year: JobExpType) => ({
        value: year.id,
        label: year.label
    }));

    return (
        <Modal
            open={visible}
            title="Tätigkeitsbereich mit meister Berufserfahrung"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Speichern
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item name="sector_id"
                           label="Tätigkeitsbereich - Sektor"
                           rules={[{required: true, message: 'Bitte wählen Sie einen Wert aus'}]}
                >
                    <Select
                        onChange={handleSectorChange}
                        options={sectorOptions}
                    />
                </Form.Item>
                <Form.Item name="subsector_id" label="Tätigkeitsbereich - Unterbereich" rules={[
                    {required: true, message: 'Bitte wählen Sie einen Wert aus'}
                ]}>
                    <Select
                        disabled={!selectedSector}
                        options={subsectorOptions}
                    />
                </Form.Item>
                <Form.Item name="job_position" label="Ihre Berufsbezeichnung">
                    <Input size={isMobile ? "large" : "middle"}/>
                </Form.Item>
                <Form.Item name="experience_years_id" label="Berufserfahrung">
                    <Select options={experienceYearOptions}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
