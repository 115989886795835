import {FC} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Card, Col, Descriptions, Row, Spin} from 'antd';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IMessage} from "../../services/types";
import {getCandidateFromMsgByIdApi} from "../../services/api";
import {PageTitle} from "../../components/PageTitle";
import {createDescriptionItems} from "../../util/utils.ts";

export const CandidateMsgDetails: FC = () => {
    const {id = ""} = useParams<{ id: string }>();

    const {data: candArr, isLoading, error}: UseQueryResult<IMessage[], Error> = useQuery({
        queryKey: ['candidate-det', id],
        queryFn: () => getCandidateFromMsgByIdApi(id),
        enabled: !!id,
    });

    const data = candArr ? candArr![0] : null;
    const client = data?._client;
    const jobDemand = data?._job_demand_with_labels;

    if (isLoading) return <Spin size="large"
                                style={{display: 'flex', justifyContent: 'center', height: 'calc(100vh - 100px)'}}/>;
    if (error) return <Alert message="Error" description={error.message} type="error"/>;
    if (!data || !client) return <Alert message="No candidate data found" type="warning"/>;

    const mainJobItems = createDescriptionItems({
        'Arbeitgeber': client!.profile_name,
        'Ansprechpartner': `${client!.salutation_label || ""}${client!.contact_name || ""}`,
        'Stellentyp': jobDemand![0].job_type_label,
        'Mindestens benötigtes Arbeitspensum': jobDemand![0].work_load_label,
        'Suchort': jobDemand![0].search_location_label,
    });

    return (
        <div>
            <PageTitle
                description={`Gesprächsverlauf ${client!.salutation_label2} ${client!.contact_name}`}
                title={data.type === "application" ? "Bewerbung bei einem Arbeitgeber" : "Direktansprache von einem Arbeitgeber"}
            />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card title={jobDemand![0].title} style={{marginBottom: '24px', backgroundColor: '#f8f9fb'}}>
                        <Descriptions column={1} items={mainJobItems}/>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

