import {useContext, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {PageTitle} from "../../components/PageTitle";
import {AuthContext, User} from "../../context/AuthContext";
import {Button, Input, message, Modal} from "antd";
import styled from "styled-components";
import {changeUserEmailApi, getMagicLink} from "../../services/api";

export const Privacy: React.FC = () => {
    const {user, setUser} = useContext(AuthContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [showPassString, setShowPassString] = useState(false);

    const queryClient = useQueryClient();

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => {
        setIsModalVisible(false);
        setNewEmail("");
        setConfirmEmail("");
    };

    const emailChangeMutation = useMutation({
        mutationFn: ({newEmail, user}: { newEmail: string, user: User }) =>
            changeUserEmailApi(newEmail, user),
        onSuccess: (updatedUser) => {
            setUser({...user, ...updatedUser});
            message.success("Bestätigungs-E-Mail zur Änderung gesendet");
            handleCancel();
            queryClient.invalidateQueries({queryKey: ['user']});
        },
        onError: () => {
            message.error("E-Mail konnte nicht geändert werden");
        }
    });

    const handlePasswordChange = async () => {
        setShowPassString(true);
        await getMagicLink(user!.email);
    };

    const handleEmailChange = () => {
        if (newEmail !== confirmEmail) {
            message.error("Emails do not match");
            return;
        }

        if (user && user.id) {
            emailChangeMutation.mutate({newEmail, user});
        } else {
            message.error("User ID is not available");
        }
    };

    return (
        <div>
            <PageTitle title="Zugangsdaten"/>
            <p>Emailadresse: {user?.email}</p>

            <BtnGroup>
                <Button type="default" onClick={showModal}>
                    Emailadresse ändern
                </Button>
                <Button
                    type="primary"
                    onClick={handlePasswordChange}
                >
                    Passwort ändern
                </Button>
            </BtnGroup>

            {showPassString && (
                <p>
                    Wir haben eine Email mit einem Link zum neu setzen Ihres Passwortes an Ihre
                    Emailadresse geschickt. Dieser Link ist 24h gültig.
                </p>
            )}

            <Modal
                title="Emailadresse ändern"
                open={isModalVisible}
                onOk={handleEmailChange}
                onCancel={handleCancel}
                okText="Speichern"
                cancelText="Zurück"
                confirmLoading={emailChangeMutation.isPending}
            >
                <Input
                    size="large"
                    placeholder="Neue Emailadresse"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    style={{marginBottom: 16}}
                />
                <Input
                    size="large"
                    placeholder="Neue Emailadresse (wiederholen)"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                />
            </Modal>
        </div>
    );
};

const BtnGroup = styled.div`
    display: flex;
    gap: 8px;
    margin: 16px 0;
    flex-direction: column;
    flex: 1;

    button,
    a {
        width: 300px;
    }
`;
