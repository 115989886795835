import {Button, Typography} from 'antd';
import styled from 'styled-components';
import {LikeOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {IMessage} from "../../../services/types.ts";
import {useNavigate} from "react-router-dom";
import {FC} from "react";
import {
    BgWrapper,
    InfoContainer,
    ItemBtns,
    ItemDetails,
    RowItemWrapper
} from "../../jobPool/components/JobPoolItem.tsx";

const {Text, Title} = Typography;

interface IMessageItemProps {
    item: IMessage;
}

export const MessageItem: FC<IMessageItemProps> = ({item}) => {
    const navigate = useNavigate();
    const formatDate = (dateString: number) => {
        return new Date(dateString).toLocaleDateString('de-DE');
    };

    const viewCandidateProfile = () => {
        navigate(`/jobs/${item._job_demand_with_labels[0].id}`);
    }
    const viewCandidateMessages = () => {
        navigate(`/messages/${item.id}`);
    }

    return (
        <RowItemWrapper>
            <BgWrapper>
                <ItemDetails style={{width: "100%"}}>
                    <InfoContainer>
                        <ContentWrapper>
                            <div style={{display: "flex"}}>
                                <IconWrapper>
                                    {item.type === "application" ?
                                        <LikeOutlined style={{fontSize: '24px', color: '#1890ff'}}/> :
                                        <QuestionCircleOutlined style={{fontSize: '24px', color: '#1890ff'}}/>}
                                </IconWrapper>
                                <Title level={5}
                                       style={{margin: 0}}>{item.type === "application" ? "Bewerbung auf eine Stellenanzeige" : "Direktansprache eines Kandidaten"}</Title>
                            </div>
                            {item._job_demand_with_labels[0]?.id ?
                                <Text>Titel: {item._job_demand_with_labels[0]!.title}</Text> :
                                <Text>Titel: Es wurde keine Stelle angehängt oder sie wurde deaktiviert</Text>}
                            {item._job_demand_with_labels[0]?.id && item._client.profile_name && <Text>Arbeitgeber: {item._client.profile_name}</Text>}
                            {item.message && <Text>Nachricht: {item.message}</Text>}
                            <Text>Kontaktaufnahme: {formatDate(item.created_at)}</Text>
                        </ContentWrapper>
                    </InfoContainer>
                    <ItemBtns>
                        <BtnStyledMessageItem type="primary" onClick={viewCandidateMessages} style={{width: 200}}>
                            Gesprächsverlauf
                        </BtnStyledMessageItem>
                        {item._job_demand_with_labels[0]?.id && <BtnStyledMessageItem onClick={viewCandidateProfile} style={{width: 200}}>
                            zur Stelle
                        </BtnStyledMessageItem>}
                    </ItemBtns>
                </ItemDetails>
            </BgWrapper>
        </RowItemWrapper>
    );
};

export const StyledCardMessage = styled.div`
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 8px;
    }
`

const IconWrapper = styled.div`
    margin-right: 16px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const ButtonGroupMessageItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;

    @media (max-width: 768px) {
        width: calc(100% - 10px);
        flex-direction: column;
        align-items: center;
    }
`;

export const BtnStyledMessageItem = styled(Button)`
    @media (max-width: 767px) {
        width: 100%;
    }
`;
