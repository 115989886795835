import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export const ErrorState = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
        window.location.reload();
    };

    return (
        <Result
            status="404"
            title="404"
            subTitle="Something went wrong, please try again!"
            extra={
                <Button type="primary" onClick={handleClick}>
                    Back Home
                </Button>
            }
        />
    );
};

