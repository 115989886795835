import {useContext, useState} from 'react';
import {PageTitle} from "../../components/PageTitle.tsx";
import {Alert, Button, Modal, Space} from "antd";
import {AuthContext, User} from "../../context/AuthContext.tsx";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteUser, modifyCandDeactivateSurvey, updateCandidate} from "../../services/api.ts";
import {DeepPartialCandidateProfile} from "../../services/types.ts";
import {SurveyModal} from "../profile/components/SurveyModal.tsx";
import {ScoreModal} from "../profile/components/ScoreModal.tsx";
import styled from "styled-components";

export const DeleteProfile = () => {
    const {user, setUser, signOut} = useContext(AuthContext);
    const queryClient = useQueryClient();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [isDeleteModalVisible, showDeleteModal] = useState(false);
    const [surveyModalVisible, setSurveyModalVisible] = useState(false);
    const [npsModalVisible, setNpsModalVisible] = useState(false);
    const [surveyResponse, setSurveyResponse] = useState<number | null>(null);
    const [npsScore, setNpsScore] = useState<number>(-1);

    const updateMutation = useMutation({
        mutationFn: updateCandidate,
        onSuccess: (data) => {
            setUser({...user, _candidate: {...user!._candidate, ...data}} as User);
            queryClient.invalidateQueries({queryKey: ['candidate']});
        },
    });

    const updateDeactivateSurveyMutation = useMutation({
        mutationFn: modifyCandDeactivateSurvey,
        onSuccess: () => {
            setSurveyModalVisible(false);
            setNpsModalVisible(true);
        },
    });

    const handleDeactivateSearch = () => {
        setConfirmModalVisible(true);
    };

    const handleActivateSearch = async () => {
        await updateMutation.mutateAsync({
            id: user!._candidate!.id,
            body: {
                id: user!._candidate!.id,
                ops: {mode: "job_search_change", status: 'active_visible'}
            } as DeepPartialCandidateProfile
        });
    };

    const handleConfirmDeactivate = async () => {
        await updateMutation.mutateAsync({
            id: user!._candidate!.id,
            body: {
                id: user!._candidate!.id,
                ops: {mode: "job_search_change", status: 'inactive'}
            } as DeepPartialCandidateProfile
        });
        setConfirmModalVisible(false);
        setSurveyModalVisible(true);
    };

    const handleSurveySubmit = async () => {
        if (surveyResponse !== null) {
            await updateDeactivateSurveyMutation.mutateAsync({
                users_id: user!.id,
                candidate_id: user!._candidate!.id,
                response: surveyResponse
            });
        }
    };

    const handleNpsSubmit = async () => {
        setNpsModalVisible(false);
    };

    const handleNpsButtonClick = (score: number) => {
        setNpsScore(score);
    };

    const handleDeleteUser = async ()=>{
        await deleteUser(user!.id)
        showDeleteModal(false)
        signOut()
    }

    const handleFromDeleteJobDeactivate = ()=>{
        showDeleteModal(false)
        setConfirmModalVisible(true)
    }

    return (
        <div>
            <PageTitle title="Konto wirklich löschen?"/>
            {user?._candidate?.ops.status !== 'inactive' ? (
                <AlertWrapper>
                    <Alert
                        showIcon
                        message="Ihre Jobsuche ist aktiv."
                        description="Sie erhalten Jobvorschläge und Ihr anonymes Kurzprofil wird Arbeitgebern im silvertalent Kandidatenpool angezeigt."
                        type="info"
                        action={
                            <Space direction="vertical">
                                <Button type="primary" onClick={handleDeactivateSearch} onTouchStart={handleDeactivateSearch}>
                                    Suche Deaktivieren
                                </Button>
                            </Space>
                        }
                    />
                </AlertWrapper>
            ) : (
                <Alert
                    message="Ihr Jobsuche ist inaktiv."
                    description="Sie erhalten keine Jobvorschläge und Ihr anonymes Kurzprofil wird Arbeitgebern aktuell nicht angezeigt."
                    type="info"
                    action={
                        <Space direction="vertical">
                            <Button type="primary" onClick={handleActivateSearch} onTouchStart={handleActivateSearch}>
                                Suche Aktivieren
                            </Button>
                        </Space>
                    }
                />
            )}
            <Button danger style={{marginTop: 16}} onClick={()=>showDeleteModal(true)}>Unwiderruflich löschen</Button>

            <Modal
                title="Wollen Sie Ihre Jobsuche wirklich deaktivieren?"
                open={confirmModalVisible}
                onOk={handleConfirmDeactivate}
                onCancel={() => setConfirmModalVisible(false)}
                okText="Suche deaktivieren"
                cancelText="Zurück"
            >
                <p>Hinweis: Wenn Sie Ihren Suchstatus auf inaktiv setzen, bekommen Sie keine Jobvorschläge für Ihre
                    Suchen mehr zugeschickt und Arbeitgeber können Ihr anonymes Kandidatenprofil nicht mehr sehen und
                    Sie nicht mehr kontaktieren.</p>
            </Modal>

            <Modal
                title="Konto wirklich löschen?"
                open={isDeleteModalVisible}
                onOk={handleDeleteUser}
                onCancel={() => showDeleteModal(false)}
                okText="Unwiderruflich löschen"
                cancelText="Zurück"
                okButtonProps={{danger: true}}
            >
                <p>Hinweis: Sind Sie wirklich sicher, dass Sie Ihr Konto durch diese Aktion unwiderruflich löschen möchten? Sollten Sie nur Ihren Suchstatus auf inaktiv setzen wollen, sodass Arbeitgeber Ihr Kurzprofil nicht sehen können und Sie keine Jobvorschläge erhalten - dann klicken Sie hier:</p>
                <Button type="link" onClick={handleFromDeleteJobDeactivate}>Nur Jobsuche deaktivieren?</Button>

            </Modal>

            <SurveyModal
                visible={surveyModalVisible}
                onOk={handleSurveySubmit}
                onCancel={() => setSurveyModalVisible(false)}
                surveyResponse={surveyResponse}
                setSurveyResponse={setSurveyResponse}
            />
            <ScoreModal
                npsModalVisible={npsModalVisible}
                npsScore={npsScore}
                handleNpsButtonClick={handleNpsButtonClick}
                handleNpsSubmit={handleNpsSubmit}
                setNpsModalVisible={setNpsModalVisible}
            />
        </div>
    );
}

export const AlertWrapper = styled.div`
    @media (max-width: 767px) {
        .ant-alert.ant-alert-info{
            flex-direction: column;
            gap: 8px;
        }
    }
`

