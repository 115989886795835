import {FC, useEffect} from 'react';
import { Button, Form, Input, Modal, Select } from "antd";
import { useQuery } from "@tanstack/react-query";
import { JobExpType } from "../../../../services/types";
import { getEducationLevels } from "../../../../services/api";
import {useMediaQuery} from "@uidotdev/usehooks";

export type initialValuesType = {
    level: string
    degree: string
    type: string
    trainings: string
    software: string
    more_title: string
}

export type formValuesType = {
    values: {
        sector_id: number
        subsector_id: number
        job_position: string
        experience_years_id: number
    }
}

interface DegreesModalProps {
    visible: boolean;
    onCancel: () => void;
    onFinish: (values: formValuesType) => void;
    initialValues: initialValuesType;
    isLoading: boolean;
}

export const DegreesModal: FC<DegreesModalProps> = ({
                                                                        visible,
                                                                        onCancel,
                                                                        onFinish,
                                                                        initialValues,
                                                                        isLoading
                                                                    }) => {
    const { data: educationLevels } = useQuery({ queryKey: ['educationLevels'], queryFn: getEducationLevels });
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    const handleOk = () => {
        form.submit();
    };

    const educationLevelOptions = educationLevels?.map((level: JobExpType) => ({
        value: level.id,
        label: level.label
    }));

    return (
        <Modal
            open={visible}
            title="Ihre Berufsausbildung"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Speichern
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={initialValues}
            >
                <Form.Item name="level_id" label="Höchster Abschluss">
                    <Select options={educationLevelOptions} />
                </Form.Item>
                <Form.Item name="degree" label="Titel Ihres höchsten Abschlusses">
                    <Input size={isMobile? "large" : "middle"}/>
                </Form.Item>
                <Form.Item name="more_title" label="Weitere Abschlüsse">
                    <Input size={isMobile? "large" : "middle"}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
