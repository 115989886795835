import {FC, useEffect} from 'react';
import { Button, Form, Input, Modal } from "antd";

export type initialValuesType = {
    city: string
    postal_code: string
    commute_time: string
    work_remote: string
    region: Array<string>
    city_manual: string
    regions_id: Array<number>
    radius: number
    street: string
    coordinates: {
        type: string
        data: {
            lng: number
            lat: number
        }
    }
}

export type formValuesType = {
    values: {
        employer_name: string
        employer_department: string
        employer_years_id: number
    }
}

interface LocationsModalProps {
    visible: boolean;
    onCancel: () => void;
    onFinish: (values: formValuesType) => void;
    initialValues: initialValuesType;
    isLoading: boolean;
}

export const LocationsModal: FC<LocationsModalProps> = ({
                                                                        visible,
                                                                        onCancel,
                                                                        onFinish,
                                                                        initialValues,
                                                                        isLoading
                                                                    }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    const handleOk = () => {
        form.submit();
    };

    return (
        <Modal
            open={visible}
            title="Bitte geben Sie hier die Anschrift Ihres Wohnorts ein"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Zurück
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Speichern
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={initialValues}
            >
                <Form.Item name="postal_code" label="Postleitzahl">
                    <Input size="large"/>
                </Form.Item>
                <Form.Item name="street" label="Straße und Hausnummer">
                    <Input size="large"/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
