import {FC, useCallback, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Alert, Card, Col, message, Row} from 'antd';
import {useMutation, useQuery} from "@tanstack/react-query";
import {getAlertConfigs, getSearchById, getSearchLocation, getSearchWhat, updateSearchById} from "../../services/api";
import {PageTitle} from "../../components/PageTitle";
import {Loader} from "../../components/Loader";
import {GeocodedMap} from "../../components/Map";
import {AddressModal} from "../../components/AddressModal";
import {IJobDetails, ILocation, IPosition} from "../../services/types";
import {ProfessionModal} from "../../components/ProfessionModal";
import {DetailItem} from "./components/DetailItem";

export const EditSearch: FC = () => {
    const {id = ""} = useParams<{ id: string }>();
    const [isLocationEditorOpen, setIsLocationEditorOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
    const [isWhatModalOpen, setIsWhatModalOpen] = useState(false);

    const {data, isLoading, refetch, error} = useQuery({
        queryKey: ['search', id],
        queryFn: () => getSearchById(Number(id)),
        enabled: !!id,
    });
    const {data: searchPositions = []} = useQuery({queryKey: ["searchPositions"], queryFn: getSearchWhat})
    const {data: alertOptions = []} = useQuery({queryKey: ["alertOptions"], queryFn: getAlertConfigs})

    const {data: searchLocation = []} = useQuery({
        queryKey: ["searchLocation"],
        queryFn: () => getSearchLocation(data?.map_location_label || "All"),
        enabled: !!data?.map_location_label
    });

    const updateMutation = useMutation({
        mutationFn: (updatedData: Partial<IJobDetails>) => updateSearchById(Number(id), updatedData),
        onSuccess: () => {
            message.success('Eingabe erfolgreich gespeichert');
            refetch();
        },
        onError: (error) => {
            message.error(`Die Stellenausschreibung konnte nicht aktualisiert werden: ${error}`);
        },
    });

    const handleSaveLocation = useCallback((location: ILocation | null) => {
        if (location && data) {
            const updatedData: Partial<IJobDetails> = {
                search_location_label: location.label,
                search_location_id: location.id,
                regions_id: location.type === 'region_id' ? Number(location.value) || 0 : 0,
                postal_code: location.type !== 'region_id' ? location.value?.toString() || '' : '',
                radius: location.radius || 0,
            };
            updateMutation.mutate(updatedData);
            setSelectedLocation(location);
        }
        setIsLocationEditorOpen(false);
    }, [data, updateMutation]);

    const handleSaveProfession = (selectedOption: IPosition | null) => {
        if (selectedOption && data) {
            const updatedData: Partial<IJobDetails> = {
                search_what_id: selectedOption.id,
                search_what_label: selectedOption.label,
            };
            updateMutation.mutate(updatedData);
        }
        setIsWhatModalOpen(false);
    };

    const handleSaveGeneric = (fields: { [key: string]: unknown }) => {
        if (data) {
            let updatedData: Partial<IJobDetails> = {};
            if ("title" in fields) {
                updatedData.title = (fields.title as { value: string }).value;
            } else {
                updatedData = {...fields} as Partial<IJobDetails>;
            }
            updateMutation.mutate(updatedData);
        }
    };

    const saveAlertType = ({value}: { value: string }) => {
        if (value) {
            handleSaveGeneric({
                alert_config_id: value
            });
        } else {
            console.warn('No matching alert option found');
        }
    }

    if (isLoading) {
        return <Loader/>;
    }

    if (error) return <Alert message="Error" description={(error as Error).message} type="error"/>;
    if (!data) return <Alert message="No search data found" type="warning"/>;

    const address = data.map_location_label;
    const searchWhatLabel: string = searchPositions.filter(el => el.id === data.search_what_id)[0]?.label || "N/A"
    const alertLabel = alertOptions.filter(el => el.id === data.alert_config_id)[0]?.config || "N/A"

    return (
        <div>
            <PageTitle title="Suchbegriff bearbeiten"/>
            <Card style={{marginBottom: '24px'}}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <DetailItem
                            title="Meine Suche"
                            content={data.title}
                            editable={data.type!=="auto"}
                            inputType="input"
                            onSave={(value) => handleSaveGeneric({title: value})}
                        />
                        <DetailItem
                            title="Gesuchte Tätigkeit"
                            content={`Was?: ${searchWhatLabel}`}
                            editable={data.type!=="auto"}
                            onEdit={() => setIsWhatModalOpen(true)}
                        />
                        {data.type==="auto" && <>Ihr Profil: <Link to="/desired-jobs">Profil bearbeiten</Link></>}

                        {!!data._candidate?.display?.potential_jobs_count &&
                            <p>Berufserfahrung: {data._candidate.display.potential_jobs_count}</p>}

                        <DetailItem
                            title="Einstellungen"
                            content={`Kandidatenvorschläge: ${alertLabel}`}
                            editable
                            inputType="select"
                            options={alertOptions.map(el => ({id: el.id, value: String(el.id), label: el.config}))}
                            onSave={saveAlertType}
                        />
                    </Col>
                    <Col span={12}>
                        <DetailItem
                            title="Suchort"
                            content={`Wo?: ${selectedLocation?.label || data?.map_location_label}`}
                            editable={data.type!=="auto"}
                            onEdit={() => setIsLocationEditorOpen(true)}
                        />
                        {data.type==="auto" && <div>
                            Ihr Profil: <Link to="/location">Profil bearbeiten</Link>
                            <ul>
                                <li>Postleitzahl: {data._candidate?.location.postal_code}</li>
                                <li>Straße: {data._candidate?.location.street ?? ""}</li>
                                <li>Suchradius: {data._candidate?.location?.radius ? data._candidate.location.radius/1000+"km" : ""}</li>
                            </ul>
                        </div>}
                        <GeocodedMap address={address}/>
                    </Col>
                </Row>
            </Card>
            <AddressModal
                open={isLocationEditorOpen}
                onClose={() => setIsLocationEditorOpen(false)}
                onSave={handleSaveLocation}
                initialValue={searchLocation[1]}
            />
            <ProfessionModal
                isModalOpen={isWhatModalOpen}
                handleSaveProf={handleSaveProfession}
                onClose={() => setIsWhatModalOpen(false)}
                initialValue={{label: searchWhatLabel, id: data.search_what_id} as IPosition}
            />
        </div>
    );
};
