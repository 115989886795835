import {QueryFunctionContext, useInfiniteQuery} from "@tanstack/react-query";
import {getMessages} from "../../../services/api.ts";
import {IMessage} from "../../../services/types.ts";

export const useMyMessages = (limit: number) => {
    return useInfiniteQuery<IMessage[], Error>({
        initialData: undefined,
        initialPageParam: undefined,
        queryKey: ['messages'],
        queryFn: async ({pageParam = 0}: QueryFunctionContext): Promise<IMessage[]> => {
            return await getMessages({
                limit,
                offset: pageParam as number,
            });
        },
        getNextPageParam: (lastPage, allPages) => {
            const nextOffset = allPages.length * limit;
            const lastPageLength = lastPage.length;
            return lastPageLength === limit ? nextOffset : undefined;
        },
        staleTime: 0,
    });
};
