import {FC, useContext, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthContext} from "../../context/AuthContext";
import {getJobByKey, getMessagesByKey} from "../../services/api";
import {AxiosError} from "axios";
import {Loader} from "client/src/components/Loader.tsx";
import {IJobDetails} from "../../services/types.ts";

export const MagicLinkRedirect: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {signOut, setError, signIn, isAuthenticated} = useContext(AuthContext);
    const hasAttemptedAuth = useRef(false);

    localStorage.setItem("appRedirectPath", location.pathname)

    useEffect(() => {
        const handleMagicLink = async () => {
            const searchParams = new URLSearchParams(location.search);
            const param2 = searchParams.get('param2');
            const id = searchParams.get('id') || "";
            const target = searchParams.get('target');

            const navigateToTarget = async () => {
                switch (target) {
                    case '9mhpkho8n7442ih74cn1wet01':
                    case 'c98fossx47tyng44pr8jd3r4c': {
                        const linkToPath = "/finances"
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case '3qprvnkfc6jfgys0adz20omsb':
                    case '0zyfk7sx422ygdox22tylvwns':
                    case 'candidate_lp_communication_list':
                    case 'candidate_lp_job_detail_communications':
                    case 'client_communication_detail':
                    case 'client_communication_candidate': {
                        const msgId = await getMessagesByKey(id)
                        const linkToPath = `/messages/${msgId[0].id}`
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case 'jobdemand_detail_page':
                    case '90ajomz550bpqje08ki8gix0e':
                    case 'ayfsb5i71ki5216qte71yolhn':
                    case 'd1uqdr81nss8p63cjl2wyeepf': {
                        const messageData: IJobDetails[] = await getMessagesByKey(id)
                        const linkToPath = `/jobs/${messageData[0]?.job_demand_id}`
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case '2ojzpauuxi6nqbk8qw2rmb34g': {
                        const IdofJobFromKey = await getJobByKey(id);
                        const linkToPath = `/jobs/${IdofJobFromKey[0].id}`
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case 'candidate_detail_page':
                    case '1vjzic6pqnq3fgjmt8le5bkmw':
                    case '2ohgbdsg7q1g020lw623gyfg9': {
                        const linkToPath = `/details/${id}`
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case '26xq2uk7glh17getfoshnntv5': {
                        const linkToPath = "/jobs"
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case 'candidate_lp_profile':
                    case '1kourgddi00entv62rtilafy5': {
                        const linkToPath = "/profile"
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    default: {
                        const linkToPath = "/"
                        localStorage.setItem('appRedirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                }
            };


            if ((param2 || id) && !hasAttemptedAuth.current) {
                if (isAuthenticated) {
                    await signOut()
                    localStorage.removeItem('appRedirectPath');
                }
                hasAttemptedAuth.current = true;
                try {
                    await signIn({
                        email: param2 ? "token@app.silvertalent.com" : "token@silvertalent.com",
                        password: param2 || id
                    })

                    navigateToTarget().then(() => {
                        setTimeout(() => localStorage.removeItem("appRedirectPath"), 2000)
                    })
                } catch (error: unknown) {
                    if (error instanceof AxiosError) {
                        if (error.response) { // Check if response is defined
                            setError(error.response.data.message);
                        } else {
                            setError('An unknown error occurred'); // Fallback error message
                        }
                        console.error('Authentication failed:', error);
                    }
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        handleMagicLink();
    }, [isAuthenticated, location, navigate, setError, signIn, signOut]);

    return <Loader/>;
};
