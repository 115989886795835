import {FC, useEffect} from 'react';
import {Button, Form, Modal, Select, Typography} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getRadiuses} from "../../../../services/api.ts";
import {Radius} from "../../../../services/types.ts";

const {Text} = Typography;


export type initialValuesType = {
    city: string
    postal_code: string
    commute_time: string
    work_remote: string
    region: Array<string>
    city_manual: string
    regions_id: Array<number>
    radius: number
    street: string
    coordinates: {
        type: string
        data: {
            lng: number
            lat: number
        }
    }
}

export type formValuesType = {
    values: {
        employer_name: string
        employer_department: string
        employer_years_id: number
    }
}

interface RadiusModalProps {
    visible: boolean;
    onCancel: () => void;
    onFinish: (values: formValuesType) => void;
    initialValues: initialValuesType;
    isLoading: boolean;
}

export const RadiusModal: FC<RadiusModalProps> = ({
                                                      visible,
                                                      onCancel,
                                                      onFinish,
                                                      initialValues,
                                                      isLoading
                                                  }) => {
    const {data: radiuses} = useQuery({queryKey: ['radiuses'], queryFn: getRadiuses});

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({radius: initialValues.radius/1000});
    }, [form, initialValues]);

    const handleOk = () => {
        form.submit();
    };
    const radiusOptions = radiuses?.map((radius: Radius) => ({
        value: radius.meters,
        label: radius.label
    }));

    return (
        <Modal
            open={visible}
            title="Bitte geben Sie hier die Anschrift Ihres Wohnorts ein"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Zurück
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Speichern
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={initialValues}
            >
                <Text>Hinweis: Die Standardeinstellung ist 10km Suchradius. In ländlichen Regionen sollten Sie einen
                    höheren Suchradius verwenden.</Text>
                <Form.Item name="radius" label="Suchradius">
                    <Select options={radiusOptions}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
