import {ReactNode, Suspense, useContext} from 'react'
import {Navigate} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {AuthContext} from "../context/AuthContext.tsx";
import {Loader} from "../components/Loader.tsx";
import {ErrorState} from "../components/ErrorState.tsx";
import {LayoutWrapper} from "../components/LayoutWrapper.tsx";
import {menuItems} from "../components/menuItems.tsx";

type Props = {
    permissions?: string[]
    roles?: string[]
    redirectTo?: string
    children: ReactNode
}

export const PrivateRoute = (props: Props) => {
    const {redirectTo = '/login', children} = props

    const {isAuthenticated, user, loadingUserData,signOut} = useContext(AuthContext)

    const hasAllPermissions = !!user

    if (loadingUserData) {
        return <Loader/>
    }

    if (!isAuthenticated) {
        return <Navigate to={redirectTo}/>
    }

    if (!hasAllPermissions) {
        return <Navigate to="/"/>
    }

    return (
        <ErrorBoundary
            fallback={<ErrorState/>}
        >
            <Suspense fallback={<Loader/>}>
                <LayoutWrapper menuItems={menuItems} signOut={signOut}>{children}</LayoutWrapper>
            </Suspense>
        </ErrorBoundary>
    )
}
