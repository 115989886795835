import {Button, Modal} from "antd";
import styled from "styled-components";
import {memo} from "react";
import {ISearchResult} from "../../../services/types.ts";
import {Link, useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteJobDemand} from "../../../services/api.ts";

export const SearchItem = memo(({item}: { item: ISearchResult }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
        mutationFn: (id: string) => deleteJobDemand(id),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['searches']});
        },
    });

    const handleViewCandidates = () => {

        const queryParams = new URLSearchParams({
            search_what_id: item.search_what_id?.toString() ?? '',
            search_what_label: item.search_what_label ?? '',
            search_location_label: item.search_location_label ?? '',
            search_location_id: item.search_location_id?.toString() ?? '',
        }).toString();

        navigate(`/jobs?${queryParams}`);
    };

    const handleDelete = () => {
        if (!item?.id) return;
        Modal.confirm({
            title: "Jobsuche wirklich löschen?",
            content: "Diese Aktion kann nicht rückgängig gemacht werden.",
            okText: "Ja",
            okType: "danger",
            cancelText: "Nein",
            onOk() {
                if (item.id) {
                    deleteMutation.mutate(item.id.toString());
                }
            },
        });
    };

    return (
        <RowItemWrapper>
            <MetaWrapper>
                <SearchTitle>{item.title ?? 'Search Title'}</SearchTitle>
                <SearchLocation>Was?: {item.search_what_label ?? 'N/A'}</SearchLocation>
                <SearchLocation>Wo?: {item.search_location_label ?? 'N/A'}</SearchLocation>
                <SearchLocation>Jobvorschläge: {item.alert_contig_label ?? 'N/A'}</SearchLocation>
            </MetaWrapper>
            <BtnGroup>
                <Button type="primary" onClick={handleViewCandidates}>Zu passenden Jobs</Button>
                <Link to={`/searches/${item.id}`}><Button style={{width: '100%'}}>Bearbeiten</Button></Link>
                <Button danger onClick={handleDelete} loading={deleteMutation.isPending}>Löschen</Button>
            </BtnGroup>
        </RowItemWrapper>
    );
});

export const RowItemWrapper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    padding: 16px;
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const SearchTitle = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 16px;
`;

const SearchLocation = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #424242;
`;

const BtnGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const MetaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
