import {
    AxiosDefaults,
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios'

import { authApi } from './api'
import { getToken, removeSessionCookies } from "../util/tokenCookies.ts";

type FailedRequestQueue = {
    onSuccess: (token: string) => void
    onFailure: (error: AxiosError) => void
}

const failedRequestQueue: FailedRequestQueue[] = []

type SetAuthorizationHeaderParams = {
    request: AxiosDefaults | AxiosRequestConfig
    token: string
}

export function setAuthorizationHeader(params: SetAuthorizationHeaderParams) {
    const { request, token } = params

    ;(request.headers as Record<string, unknown>)[
        'Authorization'
        ] = `Bearer ${token}`
}

function onRequest(config: AxiosRequestConfig) {
    const token = getToken()

    if (token) {
        setAuthorizationHeader({ request: config, token })
    }

    return config as InternalAxiosRequestConfig
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error)
}

function onResponse(response: AxiosResponse): AxiosResponse {
    return response
}

type ErrorCode = {
    code: string
}

function onResponseError(
    error: AxiosError<ErrorCode>
): Promise<AxiosError | AxiosResponse> {
    const status = error?.response?.status;

    if (status === 401) {
        const errorCode = error?.response?.data?.code;

        // Handle token expiration
        if (errorCode === 'token.expired') {
            const originalConfig = error.config as AxiosRequestConfig;

            return new Promise((resolve, reject) => {
                failedRequestQueue.push({
                    onSuccess: (token: string) => {
                        // Retry the original request with the new token
                        setAuthorizationHeader({ request: originalConfig, token });
                        resolve(authApi(originalConfig));
                    },
                    onFailure: (error: AxiosError) => {
                        reject(error);
                    }
                });
            });
        }

        // Handle other 401 errors (e.g., invalid credentials during login)
        if (error?.config?.url?.includes('/auth/login')) {
            return Promise.reject(error);
        }

        // Handle other 401 errors (e.g., unauthorized access to protected resources)
        removeSessionCookies();
        window.location.href = "/";
    }

    return Promise.reject(error);
}

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError)
    axiosInstance.interceptors.response.use(onResponse, onResponseError)

    return axiosInstance
}
