import {Alert, Button, Form, Input, Typography} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import logo from "../../assets/logo.png";
import {useLogin} from "./hooks/useLogin.ts";
import {Loader} from "client/src/components/Loader.tsx";

const {Text} = Typography;

export const Login = () => {
    const {
        onSubmit,
        styles,
        error,
        authError,
        hasRedirect
    } = useLogin();

    if (hasRedirect){
        return <Loader/>
    }

    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <div style={styles.header}>
                    <img src={logo as string} alt="Silvertalent logo" width={200}/>
                </div>
                {error && (
                    <Alert
                        message={error}
                        type="error"
                        showIcon
                        style={{marginBottom: 16}}
                    />
                )}
                <Form
                    name="auth_form"
                    onFinish={onSubmit}
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {type: "email", required: true, message: "Bitte geben Sie Ihre E-Mail-Adresse ein!"},
                        ]}
                    >
                        <Input size="large" prefix={<MailOutlined/>} placeholder="Email eingeben..."/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {required: true, message: "Bitte geben Sie Ihr Passwort ein!"},
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined/>}
                            type="password"
                            size="large"
                            placeholder="Passwort eingeben..."
                        />
                    </Form.Item>
                    <Form.Item>
                        <a
                            style={styles.forgotPassword}
                            href="https://www.silvertalent.com/password/password-reset-email"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Password vergessen?
                        </a>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button size="large" block type="primary" htmlType="submit">
                            Anmelden
                        </Button>
                        <div style={styles.footer}>
                            <div style={styles.footerBtn}>
                                <Text style={styles.text}>
                                    Sie haben noch kein Kundenkonto
                                </Text>
                                <a href={`https://www.silvertalent.com/rentner-jobs${import.meta.env.VITE_ENVIRONMENT !== 'production' ? "?env=staging" : ""}`}>Konto
                                    anlegen</a>
                            </div>
                        </div>
                    </Form.Item>

                    {authError===`Ihr Login-Link ist mittlerweile abgelaufen. Loggen Sie sich bitte neu ein, indem Sie OK drücken und dem Link "Klicken Sie hier" folgen.` && <div>
                    <p>Sie haben noch kein Passwort für Silvertalent gesetzt, oder haben Ihr Passwort vergessen?
                        Setzen Sie einfach über den Knopf unten ein Neues, damit Sie sich künftig einloggen
                        können.</p>
                    <a href="https://www.silvertalent.com/password/password-reset-email" style={{width: "100%"}}><Button style={{width: "100%"}}>Password neu
                        setzen</Button></a>
                </div>}
                </Form>
            </div>
        </section>
    );
};
